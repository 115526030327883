import { useEffect, useState } from 'react'
import { Company } from '../../../types/company'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown as arrowDown } from '@fortawesome/free-solid-svg-icons'
import { faArrowUp as arrowUp } from '@fortawesome/free-solid-svg-icons'
import CompanyStats from '../../molecules/CompanyStats/CompanyStats'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import PriceChart from '../../atoms/PriceChart'
import { CompanyNewsDate } from '../../../types/rnsList'

export interface CompanyPanelProps {
	company: Company
	companyNewsDate: CompanyNewsDate[] | undefined
}

export const CompanyPanel = (input: CompanyPanelProps) => {
	const [pricePositive, setPricePositive] = useState<boolean>(false)
	const [isLoaded, setIsLoaded] = useState(false)

	useEffect(() => {
		if (input.company.currentPriceChangePercent.startsWith('-')) {
			setPricePositive(false)
		} else {
			setPricePositive(true)
		}
	}, [input.company])

	useEffect(() => {
		setIsLoaded(true)
	}, [])

	return input.company ? (
		<div className='flex lg:flex-row w-full h-full text-4xl flex-col font-semibold pb-4'>
			<div className={`flex flex-col w-full lg:w-1/2 px-3 lg:pl-20 gap-2 ${isLoaded ? 'opacity-100 translate-y-0 transition duration-1000' : 'opacity-0 translate-y-4'}`}>
				<div className='flex flex-row gap-2 w-full text-5xl font-bold'>
					<div className='w-full'>
						{input.company.name} <span className='font-normal'>({input.company.ticker})</span>
					</div>
				</div>
				<div className='flex flex-col'>
					<CompanyStats company={input.company} />
				</div>
				<div className='w-10/12 text-xl md:text-2xl'>
					<div>
						<div className='flex flex-auto gap-1 my-2'>
							<div className='flex flex-row h-full text-3xl md:text-5xl'>{input.company.currentPrice}</div>
							<div className='h-full text-base md:text-lg'>{input.company.currency}</div>
						</div>
						<div className='flex flex-col gap-1'>
							<div className='flex flex-row flex-auto gap-1 md:text-xlg'>
								<div className={`${pricePositive ? 'text-green-400' : 'text-red-400'} flex flex-row`}>{input.company.currentPriceChange}</div>
								<div className={`${pricePositive ? 'text-green-400' : 'text-red-400'} flex flex-row gap-2`}>
									({input.company.currentPriceChangePercent})
									<div>
										<FontAwesomeIcon icon={pricePositive ? arrowUp : arrowDown} size='1x' className='text-white h-' />
									</div>
									<div className='text-sm text-white'>{getTodayText(input.company.currentPriceTime.raw)}</div>
								</div>
							</div>
							<div>
								<div className='text-sm text-slate-400 font-normal'>{`last updated: ${input.company.currentPriceTime.fmt}`}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='w-full pb-5 lg:w-1/2 p-1'>
				<PriceChart key={`${input.company.ticker}`} className='w-full h-64 md:h-80' ticker={input.company.ticker} companyNewsDate={input.companyNewsDate} />
			</div>
		</div>
	) : (
		<SkeletonTheme baseColor='#ffffff' highlightColor='#ffffff'>
			<p>
				<Skeleton count={3} />
			</p>
		</SkeletonTheme>
	)
}

function getTodayText(timestamp: number): string {
	const inputDate = new Date(timestamp * 1000)
	const today = new Date()

	// Set time to midnight for accurate comparison
	today.setHours(0, 0, 0, 0)

	if (inputDate.toDateString() === today.toDateString()) {
		return 'today'
	} else {
		const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
		return daysOfWeek[inputDate.getDay()]
	}
}
