export function addOneHourForBST(date: Date): Date {
	// Check if the input date is in BST (between the last Sunday in March and the last Sunday in October)
	const isBST = (date: Date): boolean => {
		const year = date.getFullYear()
		const marchLastSunday = new Date(year, 2, 31 - new Date(year, 2, 31).getDay())
		const octoberLastSunday = new Date(year, 9, 31 - new Date(year, 9, 31).getDay())

		return date >= marchLastSunday && date < octoberLastSunday
	}

	// If the input date is in BST, add 1 hour
	if (isBST(date)) {
		date.setHours(date.getHours() + 1)
	}

	return date
}
