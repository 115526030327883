import React, { useState } from 'react'
import { confirmPasswordReset, forgotPassword, persistRegisteredUser, signIn } from '../../services/auth' // Assuming persistRegisteredUser and signIn aren't used here
import Button from '../../components/atoms/Button/Button'
import { useLocation, useNavigate } from 'react-router-dom'

const Reset = () => {
	const location = useLocation()
	const [email, setEmail] = useState<string>('')
	const [password, setPassword] = useState<string>('')
	const [verificationCode, setVerificationCode] = useState<string>('')
	const [stage, setStage] = useState<'first' | 'second'>('first')
	const [showPassword, setShowPassword] = useState<boolean>(false)
	const [passwordPolicy, setPasswordPolicy] = useState({
		minLength: false,
		number: false,
		specialChar: false,
		uppercase: false,
		lowercase: false,
	})
	const [validations, setValidations] = useState({
		emailValid: false,
	})

	const navigate = useNavigate()

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value
		setEmail(value)
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		setValidations({ ...validations, emailValid: emailRegex.test(value) })
	}

	const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setVerificationCode(event.target.value)
	}

	const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const val = event.target.value
		setPassword(val)
		setPasswordPolicy({
			minLength: val.length >= 8,
			number: /\d/.test(val),
			specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(val),
			uppercase: /[A-Z]/.test(val),
			lowercase: /[a-z]/.test(val),
		})
	}

	const toggleShowPassword = () => {
		setShowPassword(!showPassword)
	}

	const handlePasswordResetFirst = () => {
		forgotPassword(email).then((result) => {
			if (result) {
				setStage('second')
			}
		})
	}

	const handlePasswordResetSecond = () => {
		confirmPasswordReset(email, password, verificationCode).then((result) => {
			console.log(JSON.stringify(result))
			signIn(email, password).then((authResult) => {
				if (authResult) {
					if (authResult.AccessToken.length > 1) {
						persistRegisteredUser(authResult).then(() => {
							window.location.href = '/'
						})
					}
				}
			})
		})
	}

	const policyTextClass = (condition: boolean) => (condition ? 'text-green-500' : 'text-white')
	const signup2NextDisabled = validations.emailValid === false || Object.values(passwordPolicy).every(Boolean) == false || email.length == 0

	const inputClass = (condition: boolean) =>
		condition
			? 'w-full px-3 py-2 border border-green-300 rounded-sm bg-msDark focus:outline-none'
			: 'w-full px-3 py-2 border border-gray-500 rounded-sm bg-msDark hover:border-white focus:border-white focus:outline-none'

	return (
		<div className='ali space-y-4 bg-msDark text-white pt-[100px] w-full h-screen justify-center flex flex-row p-8 xl:p-40'>
			<div className='flex flex-col gap-2'>
				{stage === 'first' ? (
					<div>
						<label htmlFor='email' className='block font-semibold'>
							Enter your user account's verified email address and we will send you a verification code.
						</label>
						<div className='mt-3'>
							<input id='email' onChange={handleEmailChange} name='email' type='email' required className={inputClass(validations.emailValid)} />
						</div>
						<Button
							disabled={!validations.emailValid}
							onClick={handlePasswordResetFirst}
							text='send code.'
							className='w-full align-middle p-5 disabled:border enabled:bg-green-500 enabled:hover:bg-green-700 text-white rounded mt-3'
						/>
					</div>
				) : (
					<div>
						<label className='block font-semibold'>Check your email and enter the verification code we sent you and provide a new password.</label>
						<div className='mt-3'>
							<input id='email' onChange={handleEmailChange} name='email' type='email' autoComplete='email' required className={inputClass(validations.emailValid)} />
						</div>
						<div className='gap-2 mt-3'>
							<label htmlFor='code' className='block text-sm'>
								verification code.
							</label>
							<input id='code' onChange={handleCodeChange} name='code' type='text' required className={`${inputClass(verificationCode.length > 2)}`} />
						</div>
						<div className='w-full mt-1'>
							<label htmlFor='password' className='block text-sm'>
								password.
							</label>
							<input
								id='password'
								onChange={handlePasswordChange}
								name='password'
								type={showPassword ? 'text' : 'password'}
								autoComplete='current-password'
								required
								className={inputClass(Object.values(passwordPolicy).every(Boolean))}
							/>
						</div>
						<div className='mt-2'>
							<label htmlFor='showPassword' className='inline-flex items-center'>
								<input id='showPassword' type='checkbox' checked={showPassword} onChange={toggleShowPassword} className='rounded shadow-sm' />
								<span className='ml-2'>Display password</span>
							</label>
						</div>
						<ul className='mt-2'>
							<li className={policyTextClass(passwordPolicy.minLength)}>Length min 8 characters</li>
							<li className={policyTextClass(passwordPolicy.number)}>Contains at least 1 number</li>
							<li className={policyTextClass(passwordPolicy.specialChar)}>Contains at least 1 special character</li>
							<li className={policyTextClass(passwordPolicy.uppercase)}>Contains at least 1 uppercase letter</li>
							<li className={policyTextClass(passwordPolicy.lowercase)}>Contains at least 1 lowercase letter</li>
						</ul>
						<Button
							className={`w-full align-middle p-5 font-bold ${!signup2NextDisabled && 'bg-green-400 text-black'}`}
							onClick={handlePasswordResetSecond}
							text='next.'
							disabled={signup2NextDisabled}
						/>
					</div>
				)}
			</div>
		</div>
	)
}

export default Reset
