import React, { useState } from 'react'
import { persistRegisteredUser, signIn } from '../../services/auth'
import Button from '../../components/atoms/Button/Button'
import { useLocation, useNavigate } from 'react-router-dom'

const LogIn = () => {
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)
	const forwardUrl: string = `${queryParams.get('forward_url')}`
	const [email, setEmail] = useState<string>('')
	const [password, setPassword] = useState<string>('')
	const [loginError, setLoginError] = useState<string>('')
	const [validations, setValidations] = useState({
		emailValid: false,
	})

	const navigate = useNavigate()

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value
		setEmail(value)
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		setValidations({ ...validations, emailValid: emailRegex.test(value) })
	}

	const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value)
	}

	const handleForgottenPassword = () => {
		navigate(`/reset`)
	}

	const handleSignupClick = () => {
		navigate(`/signup`)
	}

	const login = () => {
		setLoginError('')
		signIn(email, password)
			.then((authResult) => {
				if (authResult) {
					if (authResult.AccessToken.length > 1) {
						persistRegisteredUser(authResult).then(() => {
							if (forwardUrl && forwardUrl !== '/login' && forwardUrl !== '/signup' && forwardUrl !== 'null') {
								window.location.href = forwardUrl
							} else {
								window.location.href = '/'
							}
						})
					}
				}
			})
			.catch((err) => {
				const words = err.message.split(/(?=[A-Z])/)
				const errorString = words
					.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
					.join(' ')
					.replace('Exception', '')
					.trim()
				setLoginError(errorString)
			})
	}

	const inputClass = (condition: boolean) =>
		condition
			? 'w-full px-3 py-2 border border-green-300 rounded-sm bg-msDark focus:outline-none'
			: 'w-full px-3 py-2 border border-gray-500 rounded-sm bg-msDark hover:border-white focus:border-white focus:outline-none'

	return (
		<div className='ali space-y-4 bg-msDark text-white pt-[100px] w-full h-screen justify-center flex flex-row p-8 xl:p-40'>
			<div className='flex flex-col w-full lg:w-[600px] gap-2'>
				<h2 className='pb-8 font-extrabold text-4xl'>Log In.</h2>
				<div className=''>
					<label htmlFor='email' className='block'>
						Email address
					</label>
					<div className='w-full mt-1'>
						<input id='email' onChange={handleEmailChange} name='ms-email' type='email' autoComplete='email' required className={inputClass(validations.emailValid)} />
					</div>
				</div>
				<div>
					<label htmlFor='password' className='block text-sm'>
						Password
					</label>
					<div className='w-full mt-1'>
						<input
							id='password'
							onChange={handlePasswordChange}
							name='ms-password'
							type='password'
							autoComplete='current-password'
							required
							className={inputClass(password.length > 2)}
						/>
					</div>
				</div>
				<div className='flex items-center justify-between'>
					<div className='font-medium text-indigo-400 hover:text-indigo-500 hover:cursor-pointer' onClick={() => handleForgottenPassword()}>
						Forgot your password?
					</div>
				</div>
				<div className='font-semibold text-red-500'>{loginError}</div>
				<div className=''>
					<Button
						className={`w-full align-middle p-5 font-bold rounded disabled:border enabled:bg-green-400 enabled:text-black enabled:hover:bg-green-500 '}`}
						onClick={login}
						text='Log in.'
						disabled={email.length === 0 || password.length === 0}
					/>
				</div>
				<div className='border my-5 p-3 bg-mslight flex flex-row content-center align-middle '>
					<p className='w-full flex flex-row gap-1'>
						Don't have an account?{' '}
						<div className='font-medium text-indigo-400 hover:text-indigo-500 hover:cursor-pointer' onClick={() => handleSignupClick()}>
							Sign Up
						</div>
					</p>
				</div>
			</div>
		</div>
	)
}

export default LogIn
