import type { FunctionComponent, JSXElementConstructor, ReactElement, SVGProps } from 'react'

export type Icon = FunctionComponent<SVGProps<SVGSVGElement>>
// https://icon-sets.iconify.design/?query=add

export const ReportText: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24' {...props}>
			<path
				fill='currentColor'
				d='M5.73 15.885h12.54v-1H5.73zm0-3.385h12.54v-1H5.73zm0-3.385h8.77v-1H5.73zM4.616 19q-.69 0-1.152-.462Q3 18.075 3 17.385V6.615q0-.69.463-1.152Q3.925 5 4.615 5h14.77q.69 0 1.152.463q.463.462.463 1.152v10.77q0 .69-.462 1.152q-.463.463-1.153.463zm0-1h14.77q.23 0 .423-.192q.192-.193.192-.423V6.615q0-.23-.192-.423Q19.615 6 19.385 6H4.615q-.23 0-.423.192Q4 6.385 4 6.615v10.77q0 .23.192.423q.193.192.423.192M4 18V6z'
			></path>
		</svg>
	)
}

export const ArrowUp: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 20 20' {...props}>
			<path
				fill='currentColor'
				fillRule='evenodd'
				d='M10.103 7.222c3.447 3.468 5.744 5.764 6.89 6.887c.198.185.539.56 1.046.07c.339-.327.325-.685-.039-1.073l-7.444-7.43a.638.638 0 0 0-.455-.176a.702.702 0 0 0-.472.176l-7.453 7.635c-.241.388-.231.715.03.98c.26.265.577.28.95.043z'
			></path>
		</svg>
	)
}

export const ArrowDown: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 20 20' {...props}>
			<path
				fill='currentColor'
				d='M10.103 12.778L16.81 6.08a.69.69 0 0 1 .99.012a.726.726 0 0 1-.012 1.012l-7.203 7.193a.69.69 0 0 1-.985-.006L2.205 6.72a.727.727 0 0 1 0-1.01a.69.69 0 0 1 .99 0z'
			></path>
		</svg>
	)
}

export const PasswordShow: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 20 20' {...props}>
			<path
				d='M22.832,11.445C22.656,11.182,18.461,5,12,5S1.343,11.182,1.168,11.445L.8,12l.37.555C1.343,12.818,5.539,19,12,19s10.656-6.182,10.832-6.445L23.2,12ZM15,10a3,3,0,1,1-3-3A3,3,0,0,1,15,10Zm-3,7c-4.348,0-7.632-3.593-8.752-5A15.722,15.722,0,0,1,7.284,8.408,4.92,4.92,0,0,0,7,10a5,5,0,0,0,10,0,4.92,4.92,0,0,0-.284-1.592A15.739,15.739,0,0,1,20.752,12C19.632,13.407,16.348,17,12,17Z'
				fill='#000000'
			/>
		</svg>
	)
}

export const PasswordHide: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 20 20' {...props}>
			<path
				d='M22.832,12.445A17.226,17.226,0,0,0,17.094,7.32L20.414,4,19,2.586l-3.9,3.9A10.342,10.342,0,0,0,12,6C5.539,6,1.343,12.182,1.168,12.445L.8,13l.37.555A17.676,17.676,0,0,0,4.5,17.09L1.586,20,3,21.414l3.157-3.157A11.212,11.212,0,0,0,12,20c6.461,0,10.656-6.183,10.832-6.445L23.2,13ZM15,11a3,3,0,0,1-3,3,2.951,2.951,0,0,1-1.285-.3L14.7,9.715A2.951,2.951,0,0,1,15,11ZM9,11a3,3,0,0,1,3-3,2.951,2.951,0,0,1,1.285.3L9.3,12.285A2.951,2.951,0,0,1,9,11ZM3.248,13A15.722,15.722,0,0,1,7.284,9.408a4.832,4.832,0,0,0,.549,4.345L5.925,15.661A16.531,16.531,0,0,1,3.248,13ZM12,18a9.03,9.03,0,0,1-4.378-1.208l1.625-1.625a4.962,4.962,0,0,0,7.469-5.759A15.739,15.739,0,0,1,20.752,13C19.632,14.407,16.348,18,12,18Z'
				transform='translate(0 -1)'
				fill='#000000'
			/>
		</svg>
	)
}

export const Alert: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24' {...props}>
			<path
				fill='currentColor'
				d='m12.866 3l9.526 16.5a1 1 0 0 1-.866 1.5H2.474a1 1 0 0 1-.866-1.5L11.134 3a1 1 0 0 1 1.732 0m-8.66 16h15.588L12 5.5zM11 16h2v2h-2zm0-7h2v5h-2z'
			></path>
		</svg>
	)
}

export const Cross: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24' {...props}>
			<g fill='currentColor' fillRule='evenodd' clipRule='evenodd'>
				<path d='M5.47 5.47a.75.75 0 0 1 1.06 0l12 12a.75.75 0 1 1-1.06 1.06l-12-12a.75.75 0 0 1 0-1.06'></path>
				<path d='M18.53 5.47a.75.75 0 0 1 0 1.06l-12 12a.75.75 0 0 1-1.06-1.06l12-12a.75.75 0 0 1 1.06 0'></path>
			</g>
		</svg>
	)
}

export const Ai: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24' {...props}>
			<path
				fill='currentColor'
				d='M7.5 5.6L5 7l1.4-2.5L5 2l2.5 1.4L10 2L8.6 4.5L10 7zm12 9.8L22 14l-1.4 2.5L22 19l-2.5-1.4L17 19l1.4-2.5L17 14zM22 2l-1.4 2.5L22 7l-2.5-1.4L17 7l1.4-2.5L17 2l2.5 1.4zm-8.66 10.78l2.44-2.44l-2.12-2.12l-2.44 2.44zm1.03-5.49l2.34 2.34c.39.37.39 1.02 0 1.41L5.04 22.71c-.39.39-1.04.39-1.41 0l-2.34-2.34c-.39-.37-.39-1.02 0-1.41L12.96 7.29c.39-.39 1.04-.39 1.41 0'
			></path>
		</svg>
	)
}

export const Google: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 512 512' {...props}>
			<path
				fill='currentColor'
				d='m473.16 221.48l-2.26-9.59H262.46v88.22H387c-12.93 61.4-72.93 93.72-121.94 93.72c-35.66 0-73.25-15-98.13-39.11a140.08 140.08 0 0 1-41.8-98.88c0-37.16 16.7-74.33 41-98.78s61-38.13 97.49-38.13c41.79 0 71.74 22.19 82.94 32.31l62.69-62.36C390.86 72.72 340.34 32 261.6 32c-60.75 0-119 23.27-161.58 65.71C58 139.5 36.25 199.93 36.25 256s20.58 113.48 61.3 155.6c43.51 44.92 105.13 68.4 168.58 68.4c57.73 0 112.45-22.62 151.45-63.66c38.34-40.4 58.17-96.3 58.17-154.9c0-24.67-2.48-39.32-2.59-39.96'
			></path>
		</svg>
	)
}

export const Print: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24' {...props}>
			<path
				fill='currentColor'
				d='M17 2a1 1 0 0 1 1 1v4h3a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-3v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2H3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3V3a1 1 0 0 1 1-1zm-1 15H8v3h8zm4-8H4v8h2v-1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v1h2zM8 10v2H5v-2zm8-6H8v3h8z'
			></path>
		</svg>
	)
}

export const Check: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24' {...props}>
			<path d='M9.3,14.4,6.5,11.6,5.3,12.9l4,4,9.4-9.4L17.5,6.2Z' fill='#000000' />
		</svg>
	)
}

export const Glasses: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24' {...props}>
			<path
				fill='currentColor'
				d='m21.948 11.684l-2-6A.997.997 0 0 0 19 5h-3v2h2.279l1.334 4H15c-1.103 0-2 .897-2 2h-2c0-1.103-.897-2-2-2H4.387l1.334-4H8V5H5a.998.998 0 0 0-.948.684l-2 6l.012.004A.928.928 0 0 0 2 12v4c0 1.654 1.346 3 3 3h3c1.654 0 3-1.346 3-3v-1h2v1c0 1.654 1.346 3 3 3h3c1.654 0 3-1.346 3-3v-4a.964.964 0 0 0-.063-.313zM9 16c0 .551-.448 1-1 1H5c-.552 0-1-.449-1-1v-3h5zm11 0c0 .551-.448 1-1 1h-3c-.552 0-1-.449-1-1v-3h5z'
			/>
		</svg>
	)
}

export const ParagraphLong: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24' {...props}>
			<path fill='currentColor' d='M13 13.5H3a1 1 0 0 0 0 2h10a1 1 0 0 0 0-2m8-5H3a1 1 0 0 0 0 2h18a1 1 0 0 0 0-2m-8 10H3a1 1 0 0 0 0 2h18a1 1 0 0 0 0-2' />
		</svg>
	)
}

export const ParagraphShort: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24' {...props}>
			<path fill='currentColor' d='M13 13.5H3a1 1 0 0 0 0 2h10a1 1 0 0 0 0-2m8-5H3a1 1 0 0 0 0 2h18a1 1 0 0 0 0-2' />
		</svg>
	)
}

export const Translate: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24' {...props}>
			<path
				fill='currentColor'
				d='m11.9 22l4.55-12h2.1l4.55 12H21l-1.075-3.05h-4.85L14 22zM4 19l-1.4-1.4l5.05-5.05q-.875-.875-1.588-2T4.75 8h2.1q.5.975 1 1.7t1.2 1.45q.825-.825 1.713-2.313T12.1 6H1V4h7V2h2v2h7v2h-2.9q-.525 1.8-1.575 3.7t-2.075 2.9l2.4 2.45l-.75 2.05l-3.05-3.125zm11.7-1.8h3.6l-1.8-5.1z'
			/>
		</svg>
	)
}

export const AppsReport: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 16 16' {...props}>
			<path
				fill='currentColor'
				d='M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5L13.5 4.793L14.793 3.5L12.5 1.207zm1.586 3L10.5 3.207L4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175l-.106.106l-1.528 3.821l3.821-1.528l.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325'
			></path>
		</svg>
	)
}

export const Add: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 256 256' {...props}>
			<g fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth={16}>
				<circle cx={128} cy={128} r={112}></circle>
				<path d='M 79.999992,128 H 176.0001'></path>
				<path d='m 128.00004,79.99995 v 96.0001'></path>
			</g>
		</svg>
	)
}

export const Remove: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 256 256' {...props}>
			<g fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth={16}>
				<circle cx={128} cy={128} r={112}></circle>
				<path d='M 80.000004,128 H 176.00001'></path>
			</g>
		</svg>
	)
}

export const FavUnselected: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24' {...props}>
			<path
				fill='currentColor'
				d='m5.8 21l1.6-7L2 9.2l7.2-.6L12 2l2.8 6.6l7.2.6l-3.2 2.8H18c-.7 0-1.4.1-2.1.4l2.2-1.9l-4.4-.4l-1.7-4l-1.7 4l-4.4.4l3.3 2.9l-1 4.3l3.8-2.3l.5.3c-.2.5-.4 1.1-.4 1.6zM17 14v3h-3v2h3v3h2v-3h3v-2h-3v-3z'
			></path>
		</svg>
	)
}

export const FavSelected: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24' {...props}>
			<path fill='currentColor' d='m5.8 21l1.6-7L2 9.2l7.2-.6L12 2l2.8 6.6l7.2.6l-3.2 2.8H18c-3.1 0-5.6 2.3-6 5.3zM17 14v3h-3v2h3v3h2v-3h3v-2h-3v-3z'></path>
		</svg>
	)
}

export const Clipboard: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24' {...props}>
			<g fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth={2}>
				<rect width={8} height={4} x={8} y={2} rx={1} ry={1}></rect>
				<path d='M8 4H6a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-2M16 4h2a2 2 0 0 1 2 2v4m1 4H11'></path>
				<path d='m15 10l-4 4l4 4'></path>
			</g>
		</svg>
	)
}

export const BookmarkSelected: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24' {...props}>
			<path fill='currentColor' d='M5 21V5q0-.825.588-1.412T7 3h10q.825 0 1.413.588T19 5v16l-7-3z'></path>
		</svg>
	)
}

export const Trending: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 14 14' {...props}>
			<path
				fill='currentColor'
				fillRule='evenodd'
				d='M5.495.126A.889.889 0 0 1 6.379.11c3.987 1.852 6.244 5.698 6.105 8.914c-.06 1.351-.543 2.602-1.48 3.515c-.938.914-2.285 1.448-3.99 1.451a5.186 5.186 0 0 1-5.502-4.967v-.008a4.38 4.38 0 0 1 2.251-3.94a.5.5 0 0 1 .7.235A5.005 5.005 0 0 0 5.47 6.796c.494-.645.722-1.478.715-2.394c-.008-1.09-.35-2.259-.924-3.254A.76.76 0 0 1 5.495.126'
				clipRule='evenodd'
			></path>
		</svg>
	)
}

// export const HalfYear: Icon = (props: SVGProps<SVGSVGElement>) => {
// 	return (
// 		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 48 48' {...props}>
// 			<circle cx={24} cy={31} r={6} fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round'></circle>
// 			<path
// 				fill='none'
// 				stroke='currentColor'
// 				strokeLinecap='round'
// 				strokeLinejoin='round'
// 				d='M29.333 21.222C28.444 19.89 26.89 19 24.445 19H24a5.975 5.975 0 0 0-6 6v6'
// 			></path>
// 			<circle cx={32.5} cy={11} r={2.5} fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round'></circle>
// 			<circle cx={15.5} cy={11} r={2.5} fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round'></circle>
// 			<path
// 				fill='none'
// 				stroke='currentColor'
// 				strokeLinecap='round'
// 				strokeLinejoin='round'
// 				d='M7.5 5.5a2 2 0 0 0-2 2v33a2 2 0 0 0 2 2h33a2 2 0 0 0 2-2v-33a2 2 0 0 0-2-2Z'
// 			></path>
// 		</svg>
// 	)
// }

export const Spinner: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24' {...props}>
			<path fill='currentColor' d='M2,12A10.94,10.94,0,0,1,5,4.65c-.21-.19-.42-.36-.62-.55h0A11,11,0,0,0,12,23c.34,0,.67,0,1-.05C6,23,2,17.74,2,12Z'>
				<animateTransform attributeName='transform' dur='0.6s' repeatCount='indefinite' type='rotate' values='0 12 12;360 12 12'></animateTransform>
			</path>
		</svg>
	)
}

export const HalfYear: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 15 15' {...props}>
			<path
				fill='currentColor'
				fillRule='evenodd'
				d='M7.5.877a6.623 6.623 0 1 0 0 13.246A6.623 6.623 0 0 0 7.5.877m0 .95a5.673 5.673 0 0 0 0 11.346z'
				clipRule='evenodd'
			></path>
		</svg>
	)
}

export const FirstQuater: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 15 15' {...props}>
			<circle cx='7.5' cy='7.5' r='6.623' stroke='currentColor' strokeWidth='1' fill='none' />
			<path fill='currentColor' d='M7.5 0v7.5H15A7.5 7.5 0 0 0 7.5 0z' />
		</svg>
	)
}
export const FullYear: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 15 15' {...props}>
			<path fill='currentColor' d='M7.5 0a7.5 7.5 0 1 0 0 15a7.5 7.5 0 0 0 0-15'></path>
		</svg>
	)
}

export const Trophy: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 15 15' {...props}>
			<path
				fill='currentColor'
				fillRule='evenodd'
				d='M4.5 0A1.5 1.5 0 0 0 3 1.5V2h-.5a2.5 2.5 0 0 0 0 5h.756A4.504 4.504 0 0 0 7 9.973V14H4v1h7v-1H8V9.973A4.504 4.504 0 0 0 11.744 7h.756a2.5 2.5 0 0 0 0-5H12v-.5A1.5 1.5 0 0 0 10.5 0zM12 3v2.5c0 .169-.01.336-.027.5h.527a1.5 1.5 0 0 0 0-3zM2.5 3H3v2.5c0 .169.01.336.027.5H2.5a1.5 1.5 0 1 1 0-3'
				clipRule='evenodd'
			></path>
		</svg>
	)
}

export const HandShake: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1.25em' height='1em' viewBox='0 0 640 512' {...props}>
			<path
				fill='currentColor'
				d='m323.4 85.2l-96.8 78.4c-16.1 13-19.2 36.4-7 53.1c12.9 17.8 38 21.3 55.3 7.8l99.3-77.2c7-5.4 17-4.2 22.5 2.8s4.2 17-2.8 22.5L373 188.8l139 128V128h-.7l-3.9-2.5L434.8 79c-15.3-9.8-33.2-15-51.4-15c-21.8 0-43 7.5-60 21.2m22.8 124.4l-51.7 40.2c-31.5 24.6-77.2 18.2-100.8-14.2c-22.2-30.5-16.6-73.1 12.7-96.8l83.2-67.3c-11.6-4.9-24.1-7.4-36.8-7.4C234 64 215.7 69.6 200 80l-72 48v224h28.2l91.4 83.4c19.6 17.9 49.9 16.5 67.8-3.1c5.5-6.1 9.2-13.2 11.1-20.6l17 15.6c19.5 17.9 49.9 16.6 67.8-2.9c4.5-4.9 7.8-10.6 9.9-16.5c19.4 13 45.8 10.3 62.1-7.5c17.9-19.5 16.6-49.9-2.9-67.8zM16 128c-8.8 0-16 7.2-16 16v208c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32V128zm32 192a16 16 0 1 1 0 32a16 16 0 1 1 0-32m496-192v224c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32V144c0-8.8-7.2-16-16-16zm32 208a16 16 0 1 1 32 0a16 16 0 1 1-32 0'
			></path>
		</svg>
	)
}

export const LegalDocument: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 15 15' {...props}>
			<path
				fill='currentColor'
				fillRule='evenodd'
				d='M1 1.5A1.5 1.5 0 0 1 2.5 0h8.207L14 3.293V13.5a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 1 13.5zm3 2.497L9 4v1l-5-.003zm7 2.998H4v1h7zm0 3.006l-7-.008v1L11 11z'
				clipRule='evenodd'
			></path>
		</svg>
	)
}

export const Director: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24' {...props}>
			<path fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='m6 21l12-9M6 12l12 9M5 12h14M6 3v9m12-9v9M6 8h12M6 5h12'></path>
		</svg>
	)
}

export const Chart: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 20 20' {...props}>
			<path
				fill='currentColor'
				fillRule='evenodd'
				d='M14.02 13.997a1 1 0 0 1 1 1V17a1 1 0 0 1-1 1.001H1a1 1 0 0 1-1-1v-2.002a1 1 0 0 1 1-1.001zM19 8.007a1 1 0 0 1 1 1.002v2.001a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1.001zM11.03 2a1 1 0 0 1 1 1v2.002a1 1 0 0 1-1 1.001H1.001a1 1 0 0 1-1-1V3A1 1 0 0 1 1 2z'
			></path>
		</svg>
	)
}

export const Megaphone: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 16 16' {...props}>
			<path
				fill='currentColor'
				d='M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25 25 0 0 1 1.088.085c2.053.204 4.038.668 5.912 1.56zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 7v2c0 1.106.896 1.996 1.994 2.009l.496.008a64 64 0 0 1 1.51.048m1.39 1.081q.428.032.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48a66 66 0 0 1 1.692.064q.491.026.966.06'
			></path>
		</svg>
	)
}

export const MoneyBag: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 14 14' {...props}>
			<path
				fill='currentColor'
				fillRule='evenodd'
				d='M13.463 9.692C13.463 12.664 10.77 14 7 14S.537 12.664.537 9.713c0-3.231 1.616-4.868 4.847-6.505L4.24 1.077A.7.7 0 0 1 4.843 0H9.41a.7.7 0 0 1 .603 1.023L8.616 3.208c3.23 1.615 4.847 3.252 4.847 6.484M8.488 5.615c.152.053.326.13.438.223a.5.5 0 0 1-.64.77l-.004-.005a.527.527 0 0 0 .005.004l.003.003a.034.034 0 0 1 .007.006s-.003 0-.009-.004a1.794 1.794 0 0 0-.536-.142c-.316-.03-.615.03-.836.25c-.117.117-.194.312-.222.652a5.16 5.16 0 0 0-.01.536h1.483a.5.5 0 1 1 0 1h-1.47a3.002 3.002 0 0 1-.173.974a1.953 1.953 0 0 1-.028.068H8.75a.5.5 0 0 1 0 1h-3.5a.5.5 0 0 1-.158-.974c.29-.097.421-.253.5-.455a1.98 1.98 0 0 0 .104-.613H5.25a.5.5 0 0 1 0-1h.433a6.338 6.338 0 0 1 .015-.619c.035-.425.144-.91.511-1.276c.509-.508 1.154-.585 1.64-.538c.245.024.466.08.64.14Z'
				clipRule='evenodd'
			></path>
		</svg>
	)
}

export const Pound: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 36 36' {...props}>
			<path
				fill='currentColor'
				d='M18 2a16 16 0 1 0 16 16A16 16 0 0 0 18 2m6.5 25.92H11.74a1.25 1.25 0 0 1-.22-2.48c.15 0 1.72-.49 1.72-3.54V19h-2.5a1 1 0 0 1 0-2h2.5v-5.12a5.85 5.85 0 0 1 5.72-6a5.63 5.63 0 0 1 4.21 1.94a1.25 1.25 0 1 1-1.87 1.69A3.08 3.08 0 0 0 19 8.42a3.35 3.35 0 0 0-3.22 3.46V17h3a1 1 0 0 1 0 2h-3v2.9a7.65 7.65 0 0 1-.78 3.52h9.5a1.25 1.25 0 0 1 0 2.5'
				className='clr-i-solid clr-i-solid-path-1'
			></path>
			<path fill='none' d='M0 0h36v36H0z'></path>
		</svg>
	)
}

export const Cash: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 16 16' {...props}>
			<path fill='currentColor' d='M16 14H2v-1h13V6h1z'></path>
			<path fill='currentColor' d='M13 4v7H1V4zm1-1H0v9h14z'></path>
			<path fill='currentColor' d='M3 6H2v3h1v1h4a2.5 2.5 0 1 1 0-5H3zm8 0V5H7a2.5 2.5 0 1 1 0 5h4V9h1V6z'></path>
		</svg>
	)
}

export const ReceiveMoney: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 512 512' {...props}>
			<path
				fill='currentColor'
				d='M258 21.89c-.5 0-1.2 0-1.8.12c-4.6.85-10.1 5.1-13.7 14.81c-3.8 9.7-4.6 23.53-1.3 38.34c3.4 14.63 10.4 27.24 18.2 34.94c7.6 7.7 14.5 9.8 19.1 9c4.8-.7 10.1-5.1 13.7-14.7c3.8-9.64 4.8-23.66 1.4-38.35c-3.5-14.8-10.4-27.29-18.2-34.94c-6.6-6.8-12.7-9.22-17.4-9.22M373.4 151.4c-11 .3-24.9 3.2-38.4 8.9c-15.6 6.8-27.6 15.9-34.2 24.5c-6.6 8.3-7.2 14.6-5.1 18.3c2.2 3.7 8.3 7.2 20 7.7c11.7.7 27.5-2.2 43-8.8c15.5-6.7 27.7-15.9 34.3-24.3c6.6-8.3 7.1-14.8 5-18.5c-2.1-3.8-8.3-7.1-20-7.5c-1.6-.3-3-.3-4.6-.3m-136.3 92.9c-6.6.1-12.6.9-18 2.3c-11.8 3-18.6 8.4-20.8 14.9c-2.5 6.5 0 14.3 7.8 22.7c8.2 8.2 21.7 16.1 38.5 20.5c16.7 4.4 32.8 4.3 44.8 1.1c12.1-3.1 18.9-8.6 21.1-15c2.3-6.5 0-14.2-8.1-22.7c-7.9-8.2-21.4-16.1-38.2-20.4c-9.5-2.5-18.8-3.5-27.1-3.4m160.7 58.1L336 331.7c4.2.2 14.7.5 14.7.5l6.6 8.7l54.7-28.5zm-54.5.1l-57.4 27.2c5.5.3 18.5.5 23.7.8l49.8-23.6zm92.6 10.8l-70.5 37.4l14.5 18.7l74.5-44.6zm-278.8 9.1a40.3 40.3 0 0 0-9 1c-71.5 16.5-113.7 17.9-126.2 17.9H18v107.5s11.6-1.7 30.9-1.8c37.3 0 103 6.4 167 43.8c3.4 2.1 10.7 2.9 19.8 2.9c24.3 0 61.2-5.8 69.7-9C391 452.6 494 364.5 494 364.5l-32.5-28.4s-79.8 50.9-89.9 55.8c-91.1 44.7-164.9 16.8-164.9 16.8s119.9 3 158.4-27.3l-22.6-34s-82.8-2.3-112.3-6.2c-15.4-2-48.7-18.8-73.1-18.8'
			></path>
		</svg>
	)
}

export const Coins: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 512 512' {...props}>
			<path
				fill='currentColor'
				d='M256 243.2c141.4 0 256-47.8 256-106.7c0-58.9-114.6-106.7-256-106.7S0 77.6 0 136.5c0 58.9 114.6 106.7 256 106.7m0 170.6c-97.7 0-184.1-23.5-238.6-59.8C6.3 366 0 378.9 0 392.5c0 58.9 114.6 106.7 256 106.7s256-47.8 256-106.7c0-13.6-6.3-26.5-17.4-38.4c-54.5 36.2-140.9 59.7-238.6 59.7m0-128c-97.7 0-184.1-23.5-238.6-59.8C6.3 238 0 250.9 0 264.5c0 58.9 114.6 106.7 256 106.7s256-47.8 256-106.7c0-13.6-6.3-26.5-17.4-38.4c-54.5 36.2-140.9 59.7-238.6 59.7'
			></path>
		</svg>
	)
}

export const Target: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24' {...props}>
			<path
				fill='currentColor'
				d='M12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-2q-2.5 0-4.25-1.75T6 12t1.75-4.25T12 6t4.25 1.75T18 12t-1.75 4.25T12 18m0-2q1.65 0 2.825-1.175T16 12t-1.175-2.825T12 8T9.175 9.175T8 12t1.175 2.825T12 16m0-2q-.825 0-1.412-.587T10 12t.588-1.412T12 10t1.413.588T14 12t-.587 1.413T12 14'
			></path>
		</svg>
	)
}

export const News: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 512 512' {...props}>
			<rect width={96} height={96} x={96} y={112} fill='none' rx={16} ry={16}></rect>
			<path fill='currentColor' d='M468 112h-52v304a32 32 0 0 0 32 32a32 32 0 0 0 32-32V124a12 12 0 0 0-12-12'></path>
			<path
				fill='currentColor'
				d='M431.15 477.75A64.11 64.11 0 0 1 384 416V44a12 12 0 0 0-12-12H44a12 12 0 0 0-12 12v380a56 56 0 0 0 56 56h342.85a1.14 1.14 0 0 0 .3-2.25M96 208v-96h96v96Zm224 192H96v-32h224Zm0-64H96v-32h224Zm0-64H96v-32h224Zm0-64h-96v-32h96Zm0-64h-96v-32h96Z'
			></path>
		</svg>
	)
}

export const TwitterX: Icon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' {...props}>
			<path
				fill='currentColor'
				d='M389.2 48h70.6L305.6 224.2L487 464H345L233.7 318.6L106.5 464H35.8l164.9-188.5L26.8 48h145.6l100.5 132.9zm-24.8 373.8h39.1L151.1 88h-42z'
			></path>
		</svg>
	)
}
