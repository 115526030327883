import { useEffect } from 'react'
import Tooltip from '../ToolTip/ToolTip'

export interface InfoChipProps {
	className?: string
	title: string
	text: string
	icon?: any
	toolTip?: string
}

const InfoChip = (props: InfoChipProps) => {
	useEffect(() => {}, [])
	return (
		<Tooltip text={props.toolTip}>
			<div className='flex flex-col w-full flex-auto text-white rounded'>
				<div className={props.className}>
					<p className='font-sans text-lg text-slate-400 flex-nowrap'>{props.title}</p>
					<div className='flex flex-row font-sans'>
						{props.icon ? <div className='font-sans text-2xl text-white pr-2'>{props.icon}</div> : <></>}
						<p className='flex w-full font-sans text-2xl text-white'>{props.text}</p>
					</div>
				</div>
			</div>
		</Tooltip>
	)
}

export default InfoChip
