import React, { useState } from 'react'

export interface TabItem {
	id: string
	label: string | any
	disabled?: boolean
	counter?: number
	icon?: any
}

interface TabMenuProps {
	tabs: TabItem[]
	onTabClick?: (tabId: string) => void // Optional click handler
}

const TabMenu: React.FC<TabMenuProps> = ({ tabs, onTabClick }) => {
	const [activeTab, setActiveTab] = useState<string>(tabs[0].id)

	const handleTabClick = (tabId: string) => {
		setActiveTab(tabId)
		onTabClick?.(tabId) // Call the onTabClick if it exists
	}

	return (
		<div className='flex flex-col items-center w-full '>
			<div className='flex w-full gap-2 '>
				{tabs.map((tab) => (
					<button
						key={tab.id}
						disabled={tab.disabled}
						onClick={() => handleTabClick(tab.id)}
						className={`font-light px-2 py-2 w-full text-md rounded-md transition duration-300 ease-in-out disabled:cursor-not-allowed ${
							activeTab === tab.id ? ' bg-mslight' : 'bg-msDark '
						} ${tab.disabled ? 'text-gray-500' : 'text-white hover:bg-mslight'}`}
					>
						<div className='flex flex-row items-center'>
							{tab.icon && <span className='mr-2 text-gray-300'>{tab.icon}</span>}
							<span>{tab.label}</span>
							{(tab.counter || 0) > 0 && (
								<span className='ml-1 inline-flex items-center justify-center px-2 py-1 leading-none text-white bg-red-600 rounded-full text-xs font-normal'>
									{tab.counter}
								</span>
							)}
						</div>
					</button>
				))}
			</div>
			<div className='relative w-full bg-gray-600 h-1 rounded-full'>
				<div
					className='absolute h-1 bg-blue-500 rounded-full transition-all duration-300 ease-in-out'
					style={{
						width: `${100 / tabs.length}%`,
						transform: `translateX(${tabs.findIndex((tab) => tab.id === activeTab) * 100}%)`,
					}}
				/>
			</div>
		</div>
	)
}

export default TabMenu
