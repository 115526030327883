import { useNavigate } from 'react-router-dom'
import SearchBar from '../SearchBar/SearchBar'
import { Icon } from '../../atoms/Icon/Icon'
import { useEffect, useState } from 'react'

const Footer = () => {
	return <div className='h-100 '></div>
}

export default Footer
