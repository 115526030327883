import { Icon } from '../Icon/Icon'

export interface BookmarkProps {
	onClick: () => void
	bookmarked: boolean
}

export const Bookmark = (props: BookmarkProps) => {
	return (
		<div className=''>
			<button onClick={props.onClick} className={`${props.bookmarked ? 'text-red-400' : 'text-transparent'}`}>
				<Icon className='h-5' type={'bookmark'} />
			</button>
		</div>
	)
}
