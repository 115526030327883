import { gql } from '@apollo/client'
import { getServiceClient } from './base'
import { CompanyNewsDate, HomePageNews, ListCompanyRns, NewsFacets, Rns } from '../types/rnsList'
import axios from 'axios'

const clickRegister = 'https://nt7ekmyq33kpfeerlxb77i6mzu0wcaab.lambda-url.eu-west-2.on.aws'

export interface ShortenedURL {
	id: string
	longurl: string
	created: number
	shorturl: string
}

export async function shortenUrl(longUrl: string): Promise<string> {
	try {
		const client = await getServiceClient('url-short')
		const resp = await client.mutate({
			mutation: gql`
				mutation ShortenURL($url: String!) {
					shorten(url: $url) {
						shorturl
					}
				}
			`,
			variables: { url: longUrl },
		})
		const response: ShortenedURL = resp.data.shorten as ShortenedURL
		return response.shorturl
	} catch (error) {
		console.error(`Error getting short url for ${longUrl}`, error)
		throw error // or handle differently based on your application's needs
	}
}
