import React from 'react'
import { ChevronRight, LineChart, Globe, Database, Bell, Zap } from 'lucide-react'
import { Icon } from '../../components/atoms/Icon/Icon'

const FeatureCard = ({ icon: Icon, title, description }: { icon: any; title: string; description: string }) => (
	<div className='bg-white/5 rounded-xl p-6 backdrop-blur-sm border border-white/10 hover:border-white/20 transition-all duration-300'>
		<div className='flex flex-col gap-4'>
			<Icon className='w-8 h-8 text-green-400' />
			<h3 className='text-xl font-bold text-white'>{title}</h3>
			<p className='text-slate-300 leading-relaxed'>{description}</p>
		</div>
	</div>
)

const About = () => {
	const handleSignup = () => {
		window.location.href = `/signup`
	}

	return (
		<div className='flex flex-col gap-7 bg-msDark pt-[100px] min-h-screen text-white px-4 pb-20 max-w-7xl mx-auto'>
			{/* Hero Section */}
			<div className='flex flex-col gap-6 text-center max-w-4xl mx-auto'>
				<h1 className='text-4xl md:text-5xl font-bold bg-gradient-to-r from-green-400 to-blue-500 bg-clip-text text-transparent flex justify-center'>
					<Icon type='logo' className='w-auto' />
				</h1>
				<h2 className='text-2xl md:text-3xl font-semibold text-white'>Empowering Smarter Investing with AI-driven Data Insights</h2>
				<p className='text-lg text-slate-300 leading-relaxed max-w-3xl mx-auto'>
					In the fast-moving world of investing, MarketShare AI is here to revolutionize how you analyse and act on critical financial news. Powered by advanced AI, our
					platform provides real-time insights helping investors make confident, data-driven decisions.
				</p>
			</div>

			{/* Features Grid */}
			<div className='grid md:grid-cols-2 lg:grid-cols-3 gap-3'>
				<FeatureCard
					icon={Zap}
					title='Real-Time Analysis, Instant Action'
					description='Gain an edge by receiving instant, AI-powered analysis and ratings on every news release. With MarketShare AI, you no longer need to sift through lengthy reports, our tool highlights key insights within seconds, so you can act on opportunities without delay.'
				/>
				<FeatureCard
					icon={Globe}
					title='Comprehensive Market Coverage'
					description="MarketShare AI covers the entire London Stock Exchange, analyzing RNS releases across all sectors and company sizes. Whether you're interested in small-cap opportunities or large-cap stability, our tool delivers insights on the entire market."
				/>
				<FeatureCard
					icon={Database}
					title='Data-Driven & Unbiased'
					description='Free from the noise of influencers and market speculation, MarketShare AI delivers pure, data-driven insights. Our analysis is grounded in real data, providing objective summaries of key points, potential risks, and the overall sentiment of each news release.'
				/>
				<FeatureCard
					icon={Bell}
					title='Customizable Alerts and Tailored Experience'
					description="MarketShare AI ensures you're notified about news relevant to your portfolio and interests. Set your preferences by sector or company size, and receive updates that align with your investment strategy."
				/>
				<FeatureCard
					icon={LineChart}
					title='Actionable Insights & Upcoming Automated Trading'
					description="Beyond insights, MarketShare AI is preparing to introduce automated trading capabilities, allowing you to act on AI-driven signals directly within the platform. Soon, you'll have a seamless experience from news analysis to trade execution."
				/>
			</div>

			{/* For Whom Section */}
			<div className='bg-white/5 rounded-xl p-8 backdrop-blur-sm border border-white/10'>
				<div className='flex flex-col gap-2'>
					<h2 className='text-2xl font-bold text-white'>For Whom?</h2>
					<p className='text-slate-300 leading-relaxed'>
						MarketShare AI is ideal for both private investors looking to enhance their decision-making and financial professionals seeking to stay ahead. Our platform
						supports anyone who values objective insights, fast action, and comprehensive market visibility, all while navigating the complexities of the stock market with
						clarity.
					</p>
				</div>
			</div>

			{/* CTA Section */}
			<div className='flex justify-center'>
				<button
					onClick={handleSignup}
					className='bg-green-400 text-msDark font-bold px-8 py-4 rounded-full hover:scale-105 transition-all duration-300 flex items-center gap-2'
				>
					Get Started Today
					<ChevronRight className='w-5 h-5' />
				</button>
			</div>
		</div>
	)
}

export default About
