/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import { icons } from './icons'

export type IconTypes = keyof typeof icons.config.icon

export interface IconProps {
	type: IconTypes
	color?: string
	width?: number
	height?: number
	className?: string
	dataTestId?: string
	alt?: string
}

export const Icon: React.FC<IconProps> = ({ type, color, width, height, className, dataTestId, alt, ...rest }) => {
	const { config } = icons
	const icon = config?.icon[type]

	if (!icon) {
		return null
	}

	//@ts-ignore
	const iconWidth: number = icon?.width || config?.width
	//@ts-ignore
	const iconHeight: number = icon?.height || config?.height

	const innerHtml = alt ? `<title>${alt}</title>${icon.paths as string}` : icon.paths

	return (
		<svg
			data-icon
			data-test-id={dataTestId}
			width={`${width || iconWidth}px`}
			height={`${height || iconHeight}px`}
			viewBox={`0 0 ${iconWidth} ${iconHeight}`}
			className={className}
			dangerouslySetInnerHTML={{ __html: innerHtml }}
			{...rest}
		/>
	)
}
