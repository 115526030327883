import { LoggedInAuthResponse, signOut } from '../../../services/auth'
import { Icon } from '../../atoms/Icon/Icon'
import { TwitterX } from '../../atoms/Icons/Icons'
import { Info } from 'lucide-react'

export interface MobileMenuProps {
	isOpen: boolean
	closeMenu: any
	loggedIn: boolean
}

export const MobileMenu = (props: MobileMenuProps) => {
	if (!props.isOpen) return null // Don't render the menu if it's not open

	const handleOpenDiscord = () => {
		window.open('https://discord.gg/HPjw34vvYJ', '_blank')
	}

	const handleOpenTwitter = () => {
		window.open('https://x.com/marketshareai', '_blank')
	}

	const handleOpenInfo = () => {
		window.location.href = `/about`
	}

	const handleSignOut = () => {
		signOut().then(() => {
			window.location.href = '/'
			props.closeMenu()
		})
	}

	const handleSignIn = () => {
		window.location.href = '/login'
	}

	return (
		<div className='fixed top-16 inset-x-0 bg-msDark pt-10 h-[calc(50vh-4rem)] shadow-2xl flex flex-col justify-between items-center p-3 z-30 rounded-xl  rounded-2xl'>
			<nav className='text-white flex flex-col items-center w-full'>
				<ul>
					<li className='transition-all duration-300 ease-in-out hover:scale-125 mb-4 font-bold text-xl flex justify-center items-center w-full hover:cursor-pointer'>
						<a href='mailto:info@marketshareai.uk' className='text-xl'>
							Contact Us.
						</a>
					</li>
					{props.loggedIn ? (
						<li
							className='transition-all duration-300 ease-in-out hover:scale-125 mb-4 font-bold text-xl flex justify-center items-center w-full hover:cursor-pointer'
							onClick={handleSignOut}
						>
							Sign Out.
						</li>
					) : (
						<li
							className='transition-all duration-300 ease-in-out hover:scale-125 mb-4 font-bold text-xl flex justify-center items-center w-full hover:cursor-pointer'
							onClick={handleSignIn}
						>
							Sign In.
						</li>
					)}
				</ul>
			</nav>
			{/* Social Buttons */}
			<div className='flex justify-center gap-4 text-slate-300 pb-4'>
				<button onClick={handleOpenInfo} className='transition-all duration-300 ease-in-out hover:scale-125 hover:shadow-lg'>
					<Info className='w-[29px] h-[29px] text-slate-300' />
				</button>
				<button onClick={handleOpenTwitter} className='transition-all duration-300 ease-in-out hover:scale-125 hover:shadow-lg'>
					<div className='text-slate-300 w-[29px] h-[29px]'>
						<TwitterX />
					</div>
				</button>
				<button onClick={handleOpenDiscord} className='transition-all duration-300 ease-in-out hover:scale-125 hover:shadow-lg'>
					<Icon type='social_discord' className='w-[40px] h-[40px]' />
				</button>
			</div>
		</div>
	)
}
