import { useEffect, useRef, useState } from 'react'
import Modal from '../../atoms/Modal/Modal'
import { registerNewsView } from '../../../services/rns'
import Button from '../../atoms/Button/Button'
import Tooltip from '../../atoms/ToolTip/ToolTip'
import PulsingLight from '../../atoms/PulseLight/PulseLight'
import ReactMarkdown from 'react-markdown'
import ClockIcon from '../NewsCard/icons/clock'
import ShareMenu from '../ShareMenu/ShareMenu'
import { Icon } from '../../atoms/Icon/Icon'
import { mapMarketCap } from '../../../utils/company'
import { useNavigate } from 'react-router-dom'
import { addOneHourForBST } from '../../../utils/utils'
import React from 'react'
// import AnimatedText from '../../atoms/AnimatedText/AnimatedText'
import { Bookmark } from '../../atoms/Bookmark/Bookmark'
import AnimatedCircles from '../../atoms/AnimatedCircles'

type SentimentScore = 'UNKNOWN' | 'VERY_BAD' | 'BAD' | 'NEUTRAL' | 'GOOD' | 'VERY_GOOD'

interface keyDates {
	date: string
	description: string
	dateLabel: string
}

export interface NewsModelProps {
	id?: string
	ticker?: string
	companyIndustry?: string
	companyMarketCap?: string
	companyMarketCapDisp?: string
	newsId?: string
	title?: string
	originalTitle?: string
	url?: string
	longSummary?: string
	readingTime?: number
	bulletPoints?: string[]
	handleClose: any
	isOpen: boolean
	dateTime?: string
	sentimentScore?: SentimentScore
	sentimentReasoning?: string
	financials?: string
	brokerTargetHtml?: string
	companyDetail?: string
	keyDates?: keyDates[]
	handleNavigateToCompany?: any
	hideCloseButton?: boolean
	showHomeButton?: boolean
	onBookmarkClick?: any
	isBookmarked?: boolean
}

const today = new Date()

export const NewsModel = (inputProps: NewsModelProps) => {
	const navigate = useNavigate()

	const [modelOpen, setModelOpen] = useState<boolean>(inputProps.isOpen)
	const [showShareMenu, setShowShareMenu] = useState(false)
	const shareMenuRef = useRef<HTMLDivElement | null>(null)
	const [marketCapStyle, setMarketCapStyle] = useState<{ background: string; text: string }>()
	const [isBookmarked, setIsBookmarked] = useState<boolean>(inputProps?.isBookmarked || false)
	const [modalClass, setModalClass] = useState<string>('opacity-0 scale-95')

	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (shareMenuRef.current && !shareMenuRef.current.contains(event.target as Node)) {
				setShowShareMenu(false)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => document.removeEventListener('mousedown', handleClickOutside)
	}, [shareMenuRef])

	const handleShareButtonClick = () => {
		setShowShareMenu((prevShow) => !prevShow)
	}

	useEffect(() => {
		setModelOpen(inputProps.isOpen)
		if (inputProps.ticker && inputProps.newsId) {
			registerNewsView(inputProps.ticker, inputProps.newsId)
		}
	}, [inputProps])

	useEffect(() => {
		if (inputProps.isOpen) {
			setModalClass('opacity-100 scale-100')
		} else {
			setModalClass('opacity-0 scale-95')
		}
	}, [inputProps.isOpen])

	const handleClickHome = () => {
		window.location.href = '/'
	}
	const handleClickClose = () => {
		inputProps.handleClose()
	}
	const handleClickViewRns = () => {
		window.open(
			`https://www.londonstockexchange.com/stock/${inputProps.ticker?.toUpperCase()}/xx/analysis#:~:text=${encodeURI(inputProps.originalTitle ?? '')}`,
			'_blank',
			'noreferrer',
		)
	}

	useEffect(() => {
		const marketCapStyles: { [key: string]: { background: string; text: string } } = {
			'Large Cap': { background: 'bg-blue-500', text: 'text-black' },
			'Mid Cap': { background: 'bg-green-500', text: 'text-black' },
			'Small Cap': { background: 'bg-yellow-500', text: 'text-black' },
			'Micro Cap': { background: 'bg-red-500', text: 'text-white' },
		}
		const style = marketCapStyles[mapMarketCap(inputProps.companyMarketCap) as keyof typeof marketCapStyles]
		if (style) {
			setMarketCapStyle(style)
		}
	}, [inputProps.companyMarketCap])

	const handleTickerClick = (ticker?: string) => {
		navigate(`/company/lse/${ticker?.toLowerCase()}`)
	}

	const newsDateTime = new Date(inputProps?.dateTime as string)
	const isToday = today.getFullYear() === newsDateTime.getFullYear() && today.getMonth() === newsDateTime.getMonth() && today.getDate() === newsDateTime.getDate()

	const bulletPointsToMarkdown = (bulletPoints: string[]) => {
		return bulletPoints.map((point) => `- ${point}`).join('\n')
	}

	const menuClasses = showShareMenu ? 'opacity-100 scale-100' : 'opacity-0 scale-95'
	const markdownBulletPoints = inputProps?.bulletPoints ? bulletPointsToMarkdown(inputProps.bulletPoints) : ''

	const toggleBookmark = () => {
		if (
			inputProps.onBookmarkClick(
				{
					rnsId: inputProps.id,
					ticker: inputProps.ticker,
					market: 'lse',
				},
				isBookmarked,
			) === true
		) {
			setIsBookmarked(!isBookmarked)
		}
	}

	return modelOpen && inputProps?.dateTime ? (
		<Modal onClose={inputProps.handleClose} isOpen={modelOpen} contentClassName={`max-h-screen transition-all duration-300 transform ${modalClass}`}>
			<article>
				<div className='inset transform-gpu news-modal flex flex-col gap-1 bg-msDark shadow-2xl max-w-5xl overflow-y-auto border border-white/10 z-50 md:mt-[79px] mt-[65px] md:rounded-lg'>
					<div className='bg-msMedium p-3 pt-5 xl:px-8'>
						<div className='bg-msMedium flex flex-col rounded-lg'>
							<div className='flex flex-col pb-2 gap-2 mb-3'>
								<div className='text-4xl font-bold flex w-full mb-2'>{inputProps.title}</div>
								<div className='flex flex-row justify-left text-xs items-center gap-1'>
									{inputProps.ticker && (
										<div onClick={() => handleTickerClick(inputProps?.ticker)} className='font-bold bg-green-300 text-black px-2 py-1 rounded-xl cursor-pointer'>
											{inputProps.ticker.toUpperCase()}
										</div>
									)}
									{inputProps.companyIndustry && <div className='font-bold bg-blue-600 text-white px-2 py-1 rounded-xl'>{inputProps.companyIndustry}</div>}
									{inputProps.companyMarketCap && (
										<div className={`font-bold ${marketCapStyle?.background} ${marketCapStyle?.text} px-2 py-1 rounded-xl`}>
											<span className='flex flex-row gap-1'>
												<div>{mapMarketCap(inputProps.companyMarketCap)}: </div>
												<div className='font-normal'> {inputProps.companyMarketCapDisp}</div>
											</span>
										</div>
									)}
								</div>
							</div>
							<div className='flex flex-row gap-2 pb-3 items-center w-full'>
								<div className='flex flex-row gap-1 align-center w-full'>
									<div className='flex flex-row justify-between w-full'>
										<div className='flex flex-row gap-1 text-gray-400'>
											<div className='flex' style={{ alignSelf: 'center' }}>
												<ClockIcon />
											</div>
											<div>
												{inputProps.dateTime &&
													addOneHourForBST(newsDateTime).toLocaleString('en-GB', {
														year: 'numeric',
														month: 'long',
														day: '2-digit',
														hour: '2-digit',
														minute: '2-digit',
														weekday: 'short',
														hour12: false,
														timeZone: 'Europe/London',
													})}
											</div>
											<div className='flex'>
												{isToday && (
													<Tooltip text='trending'>
														<PulsingLight color='tomato' size='8px' />
													</Tooltip>
												)}
											</div>
										</div>
										<div className=''>
											<div className='pr-1 flex flex-col justify-end relative'>
												<div className='flex                          flex-row gap-1'>
													<span className=''>
														<button onClick={handleShareButtonClick}>
															<Icon className='h-5' type={'share'} />
														</button>
														{showShareMenu && (
															<div className={`absolute top-full right-3 z-10 transition-all duration-700 transform ${menuClasses}`} ref={shareMenuRef}>
																<ShareMenu
																	hashtags={`#marketshare #${inputProps.ticker?.toLowerCase()} @marketshareai`}
																	text={inputProps.title}
																	url={`${document.location.protocol + '//' + document.location.hostname}/news/lse/${inputProps.ticker?.toLowerCase()}/${inputProps.newsId}`}
																/>
															</div>
														)}
													</span>
													<span>
														<div className=''>{inputProps.onBookmarkClick ? <Bookmark bookmarked={isBookmarked} onClick={toggleBookmark} /> : <></>}</div>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{inputProps.sentimentScore && (
								<div className=''>
									<SentimentDisplay sentimentScore={inputProps.sentimentScore} reason={inputProps?.sentimentReasoning || ''} />
								</div>
							)}
						</div>
						<div>
							{inputProps?.financials && (
								<div className='flex flex-col pt-4 gap-5 pb-5'>
									<div className='font-bold'>Financials</div>
									<div dangerouslySetInnerHTML={{ __html: inputProps.financials }} className='prose text-white w-full' />
								</div>
							)}
						</div>
						<div className='flex flex-col gap-5 w-full mt-4 pb-6'>
							{inputProps?.longSummary?.length ? (
								<div>
									<div style={{ maxWidth: 'none' }} className='text-white prose'>
										<ReactMarkdown
											components={{
												h1: ({ node, ...props }) => <i style={{ color: 'white', fontWeight: 'bold', fontStyle: 'normal' }} {...props} />,
												h2: ({ node, ...props }) => <i style={{ color: 'white', fontWeight: 'bold', fontStyle: 'normal' }} {...props} />,
												h3: ({ node, ...props }) => <i style={{ color: 'white', fontWeight: 'bold', fontStyle: 'normal' }} {...props} />,
												h4: ({ node, ...props }) => <i style={{ color: 'white', fontWeight: 'bold', fontStyle: 'normal' }} {...props} />,
												h5: ({ node, ...props }) => <i style={{ color: 'white', fontWeight: 'bold', fontStyle: 'normal' }} {...props} />,
												h6: ({ node, ...props }) => <i style={{ color: 'white', fontWeight: 'bold', fontStyle: 'normal' }} {...props} />,
												a: ({ node, ...props }) => <i style={{ color: 'white', fontWeight: 'bold' }} {...props} />,
												strong: ({ node, ...props }) => <strong style={{ color: 'white' }} {...props} />,
											}}
											children={`
# Summary
${inputProps.longSummary}
${markdownBulletPoints}
`}
										/>
									</div>
									{inputProps.keyDates && (
										<div className='flex flex-col pt-4 gap-5 pb-5'>
											<div className='font-bold'>Key Dates</div>
											{inputProps?.keyDates?.map((kd) => {
												if (kd?.date) {
													return (
														<div key={`${kd?.date}`} className='flex flex-row gap-2'>
															<div className='w-[150px] flex-shrink-0 overflow-hidden text-ellipsis whitespace-nowrap'>{kd?.dateLabel ? kd?.dateLabel : kd?.date.trim()}</div>
															<div>{kd.description?.split('(')[0]}</div>
														</div>
													)
												}
											})}
										</div>
									)}
									{inputProps.companyDetail && (
										<div className='flex flex-col pt-4 gap-5 pb-5 w-full'>
											<div className='font-bold'>Company Summary</div>
											<div dangerouslySetInnerHTML={{ __html: inputProps.companyDetail }} className='prose text-white w-full' />
										</div>
									)}
								</div>
							) : (
								<div></div>
							)}
							{inputProps.showHomeButton && <Button text='Home' onClick={handleClickHome} className='bg-mslight hover:bg-msbright font-semibold rounded-md p-5' />}
							{inputProps.handleNavigateToCompany && (
								<Button text='View Company' onClick={inputProps.handleNavigateToCompany} className='bg-mslight hover:bg-msbright font-semibold rounded-md p-5' />
							)}
							<Button text='View RNS' onClick={handleClickViewRns} className='bg-mslight hover:bg-msbright font-semibold rounded-md p-5' />
							{!inputProps.hideCloseButton && <Button text='Close' onClick={handleClickClose} className='bg-mslight hover:bg-msbright font-semibold rounded-md p-5' />}
						</div>
					</div>
					<div className='px-3 py-3 text-xs text-slate-400'>
						<ReactMarkdown
							children={`**Disclaimer**: The information on this website is for general informational purposes only and should not be considered financial advice. We do not guarantee the accuracy or completeness of the content. Investing involves risks, and readers should conduct their research and seek professional advice before making any investment decisions. We do not endorse any specific products or companies mentioned here. Use this information at your own risk.`}
						/>
					</div>
				</div>
			</article>
		</Modal>
	) : (
		<></>
	)
}

interface SentimentDisplayProps {
	sentimentScore: SentimentScore
	reason: string
}

const SentimentDisplay: React.FC<SentimentDisplayProps> = ({ sentimentScore, reason }) => {
	const [isMountedVerdict, setIsMountedVerdict] = useState(false)
	const [isMountedLabel, setIsMountedLabel] = useState(false)

	useEffect(() => {
		setIsMountedVerdict(true)
		const timer = setTimeout(() => {
			setIsMountedLabel(true)
		}, 300)

		return () => {
			clearTimeout(timer)
		}
	}, [])

	const sentimentInfo: {
		[key in SentimentScore]: { backgroundColour: string; borderColour: string; icon: string; label: string; tone: 'Positive' | 'Negative' | 'Neutral' }
	} = {
		UNKNOWN: { backgroundColour: 'bg-gray-500', borderColour: 'border-gray-500', icon: '❓', label: 'Unknown', tone: 'Neutral' },
		VERY_BAD: { backgroundColour: 'bg-red-600', borderColour: 'border-red-600', icon: '😡', label: 'Very Bad', tone: 'Negative' },
		BAD: { backgroundColour: 'bg-orange-500', borderColour: 'border-orange-500', icon: '😟', label: 'Bad', tone: 'Negative' },
		NEUTRAL: { backgroundColour: 'bg-yellow-500', borderColour: 'border-yellow-500', icon: '😐', label: 'Neutral', tone: 'Neutral' },
		GOOD: { backgroundColour: 'bg-green-500', borderColour: 'border-green-500', icon: '🙂', label: 'Good', tone: 'Positive' },
		VERY_GOOD: { backgroundColour: 'bg-blue-500', borderColour: 'border-blue-500', icon: '😄', label: 'Very Good', tone: 'Positive' },
	}

	const { backgroundColour, borderColour, icon, label, tone } = sentimentInfo[sentimentScore as SentimentScore]

	return (
		<div className={`flex flex-col px-3 py-1 pb-4 border-2 bg-opacity-10 font-semibold ${borderColour} ${backgroundColour} text-white rounded-lg`}>
			<div className='flex items-center'>
				<div>
					<div className='flex items-center space-x-1 pt-2'>
						<Icon type='iconAnimation' className='w-auto' />
						{/* <h3 className={`text-lg font-semibold text-gray-300 transition-opacity duration-[1s] ${isMountedVerdict ? 'opacity-100' : 'opacity-0'}`}>Verdict :</h3> */}
						<h3 className={`text-lg font-semibold text-gray-300 transition-opacity duration-[1s] ${isMountedLabel ? 'opacity-100' : 'opacity-0'}`}>{label.toUpperCase()}</h3>
					</div>
				</div>
			</div>
			{reason && (
				<p className='text-gray-300 pt-3'>
					<span className='flex flex-row justify-start'>
						<div>{reason}</div>
					</span>
				</p>
			)}
		</div>
	)
}
