import { useEffect, useState } from 'react'
import { MarketPrice } from '../../../types/company'
import { Icon } from '../../atoms/Icon/Icon'

export interface MarketProps {
	marketPrice: MarketPrice | undefined
}

export const Market = (inputProps: MarketProps) => {
	const [isPositive, setIsPositive] = useState<boolean>(true)
	const [markerColour, setMarkerColour] = useState<{ textColour: string; border: string; background: string }>({
		textColour: 'text-gray-500',
		border: 'border-gray-500',
		background: 'bg-gray-500',
	})

	useEffect(() => {
		if (inputProps?.marketPrice?.changePercent) {
			setIsPositive(inputProps?.marketPrice?.changePercent?.startsWith('-') !== true)
			setMarkerColour(
				inputProps?.marketPrice?.changePercent.startsWith('-')
					? {
							textColour: 'text-red-300',
							border: 'border-red-300',
							background: 'bg-red-300',
					  }
					: {
							textColour: 'text-green-300',
							border: 'border-green-300',
							background: 'bg-green-300',
					  },
			)
		}
	}, [inputProps?.marketPrice?.changePercent])

	return (
		<div>
			<div className='w-full text-xs md:text-base flex flex-col bg-mslight p-2 rounded-t-xl border-2 border-gray-700 z-10'>
				<div className='flex flex-row justify-between items-center'>
					<div className='flex flex-col justify-center text-sm text-slate-300'>
						{inputProps?.marketPrice?.name ? <div>{inputProps?.marketPrice?.name}</div> : <div className='animate-pulse bg-gray-600 rounded h-4 w-1/2' />}
					</div>
					<div className='flex items-center'>
						{' '}
						{/* Added flex and items-center for vertical alignment */}
						{inputProps?.marketPrice?.state ? (
							<div>{inputProps?.marketPrice?.state.toUpperCase() === 'REGULAR' ? <></> : <Icon className='text-slate-300 max-h-4' type='clock' />}</div>
						) : (
							<div className='animate-pulse bg-gray-600 rounded h-4 w-1/2' />
						)}
					</div>
				</div>
				<div className='h-full text-2xl'>
					<div>
						<span className='flex gap-1 py-1'>
							{inputProps?.marketPrice?.price ? <div>{inputProps.marketPrice.price}</div> : <div className='animate-pulse bg-gray-600 rounded h-6 w-3/4' />}
						</span>
					</div>
				</div>
				<div className={`flex flex-row gap-1 text-base font-semibold ${markerColour.textColour}`}>
					{inputProps?.marketPrice?.changePercent ? (
						<>
							<div className={`h-full`}>{inputProps?.marketPrice?.priceChange ?? <div>{inputProps.marketPrice?.priceChange}</div>}</div>
							<div className='flex flex-row h-full w-full'>
								<span className='flex w-full'>
									<div>({inputProps.marketPrice.changePercent}%)</div>
									<div className='w-full flex text-white'>
										<Icon width={16} type={isPositive ? 'arrow_up' : 'arrow_down'} />
									</div>
								</span>
							</div>
						</>
					) : (
						<div className='animate-pulse bg-gray-600 rounded h-6 w-3/4' />
					)}
				</div>
			</div>
			<div className={`w-full rounded-b-xl border-b-2 ${markerColour.border} shadow-2xl h-1 bg-${markerColour.background} border-opacity-60`}></div>
		</div>
	)
}
