import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { getCompanyNews } from '../../services/rns'
import { Rns } from '../../types/rnsList'
import { NewsModel } from '../../components/molecules/NewsModel/NewsModel'
import { Icon } from '../../components/atoms/Icon/Icon'
import Button from '../../components/atoms/Button/Button'

const NewsView = () => {
	const [loading, setLoading] = useState<boolean>(false)
	const [loadingText, setLoadingText] = useState<string>('Loading...')
	const [showButtons, setShowButtons] = useState<boolean>(false)
	const { market = '', ticker = '', id = '' } = useParams()
	const [newsItem, setNewsItem] = useState<Rns>()
	const navigate = useNavigate()

	useEffect(() => {
		setLoading(true)
		setShowButtons(false)
		setLoadingText('Loading...')

		getCompanyNews(market, ticker, id).then((res: Rns | undefined) => {
			if (!res) return // 404
			if (res.processing) {
				setLoadingText('Processing news...')
			}
			setNewsItem(res)
			setLoading(false)
			setShowButtons(true)
		})
	}, [id])

	const handleNavigateToCompany = () => {
		navigate(`/company/lse/${ticker}`)
	}

	const handleClickViewRns = () => {
		console.log('handleClickViewRns', `https://www.londonstockexchange.com/stock/${ticker}/xx/analysis`)
		// window.open(`https://www.londonstockexchange.com/stock/${ticker}/xx/analysis`, '_blank', 'noreferrer')
		window.open(`https://www.londonstockexchange.com/stock/${ticker.toUpperCase()}/xx/analysis`, '_blank', 'noreferrer')
	}

	if (loading || newsItem?.processing) {
		return (
			<div className='h-screen bg-msDark flex flex-col items-center justify-center'>
				<div className='flex flex-col items-center gap-12'>
					{' '}
					{/* Increased gap for better spacing */}
					<div className='flex flex-col items-center gap-4'>
						{' '}
						{/* Increased gap for better spacing */}
						<Icon type='iconAnimation' className='w-12 h-12' /> {/* Slightly larger icon */}
						<div
							className='text-white font-bold transition-opacity duration-500 text-lg' // Added text-lg for better proportion
						>
							{loadingText}
						</div>
					</div>
					<div
						className={`flex gap-6 transition-opacity duration-500 ${
							// Increased gap between buttons
							showButtons ? 'opacity-100' : 'opacity-0'
						}`}
					>
						<Button
							text='View RNS'
							onClick={handleClickViewRns}
							className='bg-mslight hover:bg-msbright font-semibold rounded-md px-8 py-4 text-gray-400 min-w-[140px] text-center'
						/>
						<Button
							text='View Company'
							onClick={handleNavigateToCompany}
							className='bg-mslight hover:bg-msbright font-semibold rounded-md px-8 py-4 text-gray-400 min-w-[140px] text-center'
						/>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className='flex h-screen overflow-auto font-sans text-white bg-msDark'>
			<div className=''>
				<NewsModel
					isOpen={true}
					title={newsItem?.generatedData ? newsItem.generatedData.headline : newsItem?.headline}
					originalTitle={newsItem?.headline}
					url={newsItem?.url}
					dateTime={newsItem?.date ?? undefined}
					longSummary={newsItem?.generatedData ? newsItem?.generatedData.longSummary : newsItem?.headline}
					bulletPoints={newsItem?.generatedData ? newsItem?.generatedData.bulletPoints : undefined}
					handleClose={handleNavigateToCompany}
					handleNavigateToCompany={handleNavigateToCompany}
					newsId={newsItem?.id}
					ticker={newsItem?.company.ticker}
					companyIndustry={newsItem?.company?.industry}
					companyMarketCap={newsItem?.company?.marketCap}
					companyMarketCapDisp={newsItem?.company?.marketCapDisp}
					keyDates={newsItem?.generatedData?.keyDates}
					sentimentScore={newsItem?.generatedData?.sentimentScore}
					sentimentReasoning={newsItem?.generatedData?.sentimentReasoning}
					companyDetail={newsItem?.generatedData?.companyDetail}
					hideCloseButton={true}
					showHomeButton={true}
					financials={newsItem?.generatedData?.financials}
					id={newsItem?.id}
				/>
			</div>
		</div>
	)
}

export default NewsView
