import { gql } from '@apollo/client'
import { getServiceClient } from './base'
import { Bookmark } from '../types/bookmark'

export async function addBookmark(bm: Bookmark): Promise<void> {
	try {
		const client = await getServiceClient('user')
		await client.mutate({
			mutation: gql`
				mutation {
					addBookmark(market: "${bm.market}", ticker: "${bm.ticker}", rnsId: "${bm.rnsId}")
				}
			`,
		})
	} catch (error) {
		console.error(`Error adding bookmark`, error)
	}
	return
}

export async function removeBookmark(bm: Bookmark): Promise<void> {
	try {
		const client = await getServiceClient('user')
		await client.mutate({
			mutation: gql`
				mutation {
					removeBookmark(market: "${bm.market}", ticker: "${bm.ticker}", rnsId: "${bm.rnsId}")
				}
			`,
		})
	} catch (error) {
		console.error(`Error removing bookmark`, error)
	}
	return
}

export async function getUserBookmarks(): Promise<Bookmark[]> {
	try {
		const client = await getServiceClient('user')
		const resp = await client.query({
			query: gql`
				query {
					getUserBookmarks {
						market
						rnsId
						ticker
					}
				}
			`,
		})
		return resp.data.getUserBookmarks as Bookmark[]
	} catch (error) {
		console.error(`Error getting bookmarks`, error)
	}
	return []
}
