import { jwtDecode } from 'jwt-decode'

const cognitoBaseURL = 'https://cognito-idp.eu-west-2.amazonaws.com/'
const guestApi = 'https://rlafeu7v1a.execute-api.eu-west-2.amazonaws.com/guest'

function getCognitoConfig() {
	// TODO put this on an api
	return {
		clientId: '1je7386bod32qbasiese78hhj3',
	}
}
export type AuthResult = {
	AccessToken: string
	RefreshToken?: string
	IdToken: string
	ExpiresIn: number
}

export type LoggedInAuthResponse = {
	access_token: string
	expires_in: number
	token_type: string
	refresh_token?: string
	auth_type: 'registered' | 'guest'
	user?: {
		plan: 'FREE' | 'PAID'
		firstName: string
	}
}

export const validateUser = async (token: LoggedInAuthResponse): Promise<void> => {
	if (token.expires_in < Math.floor(Date.now() / 1000)) {
		if (token.auth_type === 'guest') {
			await getGuestToken()
		} else {
			await refreshToken(token)
		}
	}
}

export const refreshToken = async (user: LoggedInAuthResponse): Promise<boolean> => {
	const cognitoConfig = getCognitoConfig()

	const payload = {
		AuthFlow: 'REFRESH_TOKEN_AUTH',
		AuthParameters: {
			REFRESH_TOKEN: user.refresh_token,
		},
		ClientId: cognitoConfig.clientId,
	}

	try {
		const response = await makeCognitoRequest('InitiateAuth', payload)
		const authResult = { ...response, RefreshToken: user.refresh_token }
		await persistRegisteredUser(authResult)
		return true
	} catch (error: any) {
		return false
	}
}

export const signUp = async (username: string, password: string, firstname: string, surname: string, plan: string): Promise<AuthResult> => {
	const cognitoConfig = getCognitoConfig()

	const payload = {
		ClientId: cognitoConfig.clientId,
		Username: username,
		Password: password,
		UserAttributes: [
			{ Name: 'given_name', Value: firstname },
			{ Name: 'family_name', Value: surname },
			{ Name: 'custom:plan', Value: plan },
		],
	}
	return await makeCognitoRequest('SignUp', payload)
}

export const signIn = async (username: string, password: string): Promise<AuthResult> => {
	const cognitoConfig = getCognitoConfig()
	const payload = {
		AuthFlow: 'USER_PASSWORD_AUTH',
		AuthParameters: {
			PASSWORD: password,
			USERNAME: username,
		},
		ClientId: cognitoConfig.clientId,
	}

	return await makeCognitoRequest('InitiateAuth', payload)
}

export const verifyEmail = async (email: string, verificationCode: string): Promise<boolean> => {
	const cognitoConfig = getCognitoConfig()
	const payload = {
		ClientId: cognitoConfig.clientId,
		Username: email,
		ConfirmationCode: verificationCode,
	}
	await makeCognitoRequest('ConfirmSignUp', payload)
	return true
}

export const forgotPassword = async (email: string): Promise<boolean> => {
	const cognitoConfig = getCognitoConfig()
	const payload = {
		ClientId: cognitoConfig.clientId,
		Username: email,
	}
	await makeCognitoRequest('ForgotPassword', payload)
	return true
}

export const confirmPasswordReset = async (email: string, password: string, code: string) => {
	const cognitoConfig = getCognitoConfig()
	const payload = {
		ClientId: cognitoConfig.clientId,
		Username: email,
		Password: password,
		ConfirmationCode: code,
	}
	return await makeCognitoRequest('ConfirmForgotPassword', payload)
}

export const resentVerifyEmail = async (email: string): Promise<boolean> => {
	const cognitoConfig = getCognitoConfig()
	const payload = {
		ClientId: cognitoConfig.clientId,
		Username: email,
	}
	await makeCognitoRequest('ResendConfirmationCode', payload)
	return true
}

const makeCognitoRequest = async (target: string, payload: any): Promise<AuthResult> => {
	const request = await fetch(cognitoBaseURL, {
		method: 'POST',
		body: JSON.stringify(payload),
		headers: {
			'X-Amz-Target': `AWSCognitoIdentityProviderService.${target}`,
			'Content-Type': 'application/x-amz-json-1.1',
		},
	})

	const response = await request.json()
	if (!request.ok) {
		throw new Error(response?.__type)
	}
	return response.AuthenticationResult
}

export const getGuestToken = async (): Promise<void> => {
	const request = await fetch(guestApi, {
		method: 'POST',
	})

	const response = await request.json()
	if (!request.ok) {
		throw new Error(response?.__type)
	}
	persistGuestUser(response).then(() => {
		return
	})
}

export const isRegistered = () => {
	const authSession = localStorage.getItem('sessionAuth')
	if (authSession) {
		return JSON.parse(authSession).auth_type === 'registered'
	}
	return false
}

export const persistRegisteredUser = async (authResult: AuthResult) => {
	const decoded = jwtDecode(authResult.IdToken) as any
	localStorage.setItem('decoded', JSON.stringify(decoded))
	localStorage.setItem('authResult', JSON.stringify(authResult))
	localStorage.setItem(
		'sessionAuth',
		JSON.stringify({
			access_token: authResult.IdToken, //We use the ID token to auth with the backend (this is to maintain compatibility with the legacy site)
			refresh_token: authResult.RefreshToken,
			expires_in: decoded.exp,
			token_type: 'Bearer',
			auth_type: 'registered',
			user: {
				plan: decoded['custom:plan'] || 'FREE',
				firstName: decoded['given_name'],
			},
		} as LoggedInAuthResponse),
	)
}

export const persistGuestUser = async (guestToken: { access_token: string; expires_in: number; token_type: string }) => {
	localStorage.setItem(
		'sessionAuth',
		JSON.stringify({
			access_token: guestToken.access_token,
			expires_in: guestToken.expires_in,
			token_type: 'Bearer',
			auth_type: 'guest',
		} as LoggedInAuthResponse),
	)
}

export const refreshAuth = async (): Promise<string> => {
	const session = JSON.parse(localStorage.getItem('sessionAuth')!) as LoggedInAuthResponse
	if (!session) return ''
	if (session.auth_type === 'guest') {
		await getGuestToken()
		return (JSON.parse(localStorage.getItem('sessionAuth')!) as LoggedInAuthResponse).access_token
	}
	if (!(await refreshToken(session))) {
		console.log('refresh token expired, use guest token')
		await getGuestToken()
	}
	return (JSON.parse(localStorage.getItem('sessionAuth')!) as LoggedInAuthResponse).access_token
}

export const signOut = async (): Promise<boolean> => {
	localStorage.removeItem('sessionAuth')
	return true
}
