import React from 'react'
import { useNavigate } from 'react-router-dom'
import Modal from '../../atoms/Modal/Modal'

interface SignInPromptModalProps {
	isOpen: boolean
	onClose: () => void
}

const SignInPromptModal: React.FC<SignInPromptModalProps> = ({ isOpen, onClose }) => {
	const navigate = useNavigate()

	if (!isOpen) {
		return null
	}

	// Stops propagation for modal content clicks
	const stopPropagation = (e: React.MouseEvent) => e.stopPropagation()

	return (
		<Modal isOpen={isOpen} onClick={onClose} modelContentClassName='items-center'>
			<div className='bg-mslight rounded-lg p-6 space-y-4 max-w-sm w-full' onClick={stopPropagation}>
				<h2 className='text-2xl font-semibold text-center'>Join Our Community</h2>
				<p className='text-sm text-center'>Sign up to discover more insights, or log in if you already have an account.</p>
				<div className='flex flex-col space-y-2'>
					<button
						onClick={() => {
							onClose()
							navigate('/signup')
						}}
						className='w-full align-middle p-2 font-bold rounded border duration-75 ease-in-out hover:scale-105 hover:bg-slate-200 hover:text-black'
					>
						Sign Up
					</button>
					<button
						onClick={() => {
							onClose()
							navigate('/login')
						}}
						className='rounded font-bold bg-green-400 duration-75 ease-in-out hover:scale-105 text-msMedium p-3 hover:bg-slate-200 transition-all'
					>
						Log In
					</button>
				</div>
				<button onClick={onClose} className='absolute top-2 right-2 text-gray-500 hover:text-gray-700'>
					<svg className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
						<path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12' />
					</svg>
				</button>
			</div>
		</Modal>
	)
}

export default SignInPromptModal
