import { FC, useEffect, useRef, useState } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
import HighchartsIndicators from 'highcharts/indicators/indicators'
import HighchartsAnnotations from 'highcharts/modules/annotations'
import accessibility from 'highcharts/modules/accessibility'
import { getCompanyPrices } from '../../../services/company'
import { CompanyNewsDate, Rns } from '../../../types/rnsList'
import { listCompanyRns } from '../../../services/rns'

HighchartsIndicators(Highcharts)
HighchartsAnnotations(Highcharts)
accessibility(Highcharts)

interface PriceChartProps {
	ticker: string
	className: string
	companyNewsDate: CompanyNewsDate[] | undefined
}

const sentimentAbbreviations = {
	UNKNOWN: 'UN',
	VERY_BAD: 'VB',
	BAD: 'B',
	NEUTRAL: 'N',
	GOOD: 'G',
	VERY_GOOD: 'VG',
}

const sentimentColors = {
	UNKNOWN: '#6b7280',
	VERY_BAD: '#dc2626',
	BAD: '#f97316',
	NEUTRAL: '#eab308',
	GOOD: '#22c55e',
	VERY_GOOD: '#3b82f6',
}

const initialOptions = {
	navigator: { enabled: false },
	scrollbar: { enabled: false },
	credits: { enabled: false },
	chart: { backgroundColor: 'transparent' },
	plotOptions: {
		series: { zIndex: 2, animation: true, lineWidth: 3 },
		column: { zIndex: 1, animation: true },
		area: { fillOpacity: 0.5, color: 'rgba(0, 0, 0, 0.9)' },
	},
	yAxis: [
		{
			labels: {
				style: {
					color: '#FFFFFF',
				},
			},
			title: {
				text: 'Price',
				style: {
					color: '#FFFFFF',
				},
			},
			opposite: false,
			top: '0%',
			height: '100%',
			gridLineColor: 'rgba(0, 0, 0, 0.3)',
		},
		{
			labels: {
				style: {
					color: '#FFFFFF',
				},
			},
			title: {
				text: 'Volume',
				style: {
					color: '#FFFFFF',
				},
			},
			top: '0%',
			height: '100%',
			gridLineColor: 'rgba(0, 0, 0, 1)',
		},
		{ height: '20%' },
	],
	series: [],
	rangeSelector: {
		enabled: false,
		inputEnabled: false,
		selected: 1,
	},
}

Highcharts.theme = {
	lang: {
		rangeSelectorZoom: ' ',
	},
}
Highcharts.setOptions(Highcharts.theme)

function calculateStartDate(range: string) {
	const endDate = new Date()
	switch (range) {
		case '1d':
			return new Date(endDate.setDate(endDate.getDate() - 1))
		case '5d':
			return new Date(endDate.setDate(endDate.getDate() - 5))
		case '1mo':
			return new Date(endDate.setMonth(endDate.getMonth() - 1))
		case '3mo':
			return new Date(endDate.setMonth(endDate.getMonth() - 3))
		case '6mo':
			return new Date(endDate.setMonth(endDate.getMonth() - 6))
		case 'ytd':
			return new Date(new Date().getFullYear(), 0, 1)
		case '1y':
			return new Date(endDate.setFullYear(endDate.getFullYear() - 1))
		case '5y':
			return new Date(endDate.setFullYear(endDate.getFullYear() - 5))
		case 'max':
			return new Date(0) // epoch date for max
		default:
			return new Date()
	}
}

const PriceChart: FC<PriceChartProps> = ({ ticker, className, companyNewsDate }) => {
	const [priceData, setPriceData] = useState<[number, number | null][]>([])
	const [volumeData, setVolumeData] = useState<[number, number][]>([])
	const chartRef = useRef<HighchartsReact.RefObject>(null)
	const [range, setRange] = useState('6mo')
	const [news, setNews] = useState<{ x: number; title: string; text: string; fillColor: string }[] | undefined>()
	const [loading, setLoading] = useState<boolean>(false)

	const handleRangeSelectorClick = (range: string) => {
		setRange(range)
	}

	const fetchPriceData = async (ticker: string, range: string) => {
		setLoading(true)
		const prices = await getCompanyPrices('lse', ticker, range)
		const formattedPriceData: [number, number | null][] = prices.map((price) => [new Date(price.x).getTime(), price.y ? parseFloat(price.y) : null])
		const formattedVolumeData: [number, number][] = prices
			.map((d) => [d.x !== null ? new Date(d.x).getTime() : null, d.volume !== null ? parseFloat(d.volume) : null])
			.filter((d): d is [number, number] => d[0] !== null && d[1] !== null)
		setPriceData(formattedPriceData)
		setVolumeData(formattedVolumeData)
		setLoading(false)
	}

	useEffect(() => {
		fetchPriceData(ticker, range)
	}, [ticker, range])

	useEffect(() => {
		const newsIds = new Set()
		const startDate = calculateStartDate(range)
		listCompanyRns('lse', ticker, 30).then((newsHistory) => {
			const newNewsHistoryData = newsHistory.items
				?.filter((newsItem) => {
					if (new Date(newsItem.date) < startDate) {
						return false
					}
					if (newsIds.has(newsItem.headline)) {
						return false
					} else {
						newsIds.add(newsItem.headline)
						return true
					}
				})
				.map((newsItem: Rns) => {
					const sentiment = newsItem?.generatedData?.sentimentScore || 'UN'
					return {
						x: new Date(newsItem.date).getTime(),
						title: sentimentAbbreviations[sentiment as keyof typeof sentimentAbbreviations],
						text: `<span style="color: black;">${newsItem?.generatedData?.headline} (${sentiment})</span>`,
						fillColor: sentimentColors[sentiment as keyof typeof sentimentColors] || '#6b7280',
					}
				})
			setNews(newNewsHistoryData)
		})
	}, [range])

	const maxPriceDate = priceData.length ? new Date(Math.max(...priceData.map((d) => d[0]))) : new Date()
	const extendedMaxDate = new Date(maxPriceDate)
	extendedMaxDate.setDate(maxPriceDate.getDate() + 1000)

	const ops = {
		...initialOptions,
		series: [
			{
				name: ticker,
				data: priceData,
				id: ticker,
				type: 'line',
				connectNulls: true,
				yAxis: 0, // Link to the first yAxis (Price)
				dataGrouping: {
					enabled: false,
				},
			},
			{
				name: 'Volume',
				type: 'column',
				data: volumeData,
				yAxis: 1, // Link to the second yAxis (Volume)
				dataGrouping: {
					enabled: false,
				},
			},
			{
				type: 'flags',
				data: news,
				onSeries: ticker,
				shape: 'circlepin',
				width: 15,
				height: 15,
				lineColor: '#94a3b8',
				dataGrouping: {
					enabled: false,
				},
			},
		],
	}

	if (loading) {
		return (
			<div className='animate-pulse pt-10'>
				<div className='h-4 bg-gray-600 rounded w-3/4 mb-3'></div>
				<div className='h-4 bg-gray-600 rounded w-1/2 mb-3'></div>
				<div className='h-4 bg-gray-600 rounded w-1/4 mb-3'></div>
				<div className='h-4 bg-gray-600 rounded w-2/3 mb-3'></div>
				<div className='h-4 bg-gray-600 rounded w-3/5 mb-3'></div>
				<div className='h-4 bg-gray-600 rounded w-2/5 mb-3'></div>
				<div className='h-4 bg-gray-600 rounded w-4/5 mb-3'></div>
				<div className='h-4 bg-gray-600 rounded w-1/2 mb-3'></div>
			</div>
		)
	}

	return (
		<div className={className}>
			<div className='flex justify-start mb-2 pl-1 pt-3'>
				{['1d', '5d', '1mo', '3mo', '6mo', 'ytd', '1y', '5y', 'max'].map((r) => (
					<button
						key={r}
						className={`mx-0.5 px-1 py-1 text-sm border rounded border-gray-700 ${range === r ? 'bg-msLight text-white' : ' text-gray-400'}`}
						onClick={() => handleRangeSelectorClick(r)}
					>
						{r}
					</button>
				))}
			</div>
			<HighchartsReact highcharts={Highcharts} constructorType='stockChart' options={ops} containerProps={{ className: 'w-full h-full' }} ref={chartRef} />
		</div>
	)
}

export default PriceChart
