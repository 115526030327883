import { useEffect, useState } from 'react'
import { Rns } from '../../../types/rnsList'
import NewsCard from '../../molecules/NewsCard/NewsCard'
import { NewsModel } from '../../molecules/NewsModel/NewsModel'
import { addBookmark, removeBookmark } from '../../../services/user'
import { Bookmark } from '../../../types/bookmark'
import { isRegistered } from '../../../services/auth'
import SignInRegisterModal from '../../molecules/SignInRegisterModal/SignInRegisterModal'
import './index.css'

export interface NewsViewProps {
	market: string
	ticker: string
	newsId?: string
	newsList: Rns[]
	clickLoadMoreNews: any
	bookmarks?: Set<string>
	newsPaging:
		| {
				totalResults: number
				lastKey: {
					type: string
					sk: string
				}
		  }
		| undefined
}

const NewsView = (inputProps: NewsViewProps) => {
	const [newsSelected, setNewsSelected] = useState<Rns>()
	const [pagesLoading, setPagesLoading] = useState<boolean>(false)
	const [firstLoad, setFirstLoad] = useState<boolean>(true)
	const [showSignInModal, setShowSignInModal] = useState(false)
	const [usersBookmarkSet, setUsersBookmarkSet] = useState<Set<string>>()
	const [newsList, setNewsList] = useState<Rns[]>()

	useEffect(() => {
		if (newsSelected) {
			document.body.classList.add('no-scroll')
		} else {
			document.body.classList.remove('no-scroll')
		}
	}, [newsSelected])

	useEffect(() => {
		setNewsList(inputProps.newsList)
	}, [inputProps.newsList])

	useEffect(() => {
		setUsersBookmarkSet(inputProps.bookmarks)
	}, [inputProps.bookmarks])

	useEffect(() => {
		setPagesLoading(true)
		if (inputProps.newsId) {
			const inputNews = inputProps?.newsList?.find((n) => {
				return n.id?.toLowerCase().trim() === inputProps.newsId!.toLowerCase().trim()
			})
			if (inputNews && firstLoad) {
				setNewsSelected(inputNews)
			}
		}
		setPagesLoading(false)
	}, [firstLoad, inputProps.newsId])

	const handleNewsSelected = (rns: Rns) => {
		setNewsSelected(rns)
	}

	const handleNewsModelClose = () => {
		setNewsSelected(undefined)
		setFirstLoad(false)
	}

	const bookmarkClick = (newsItem: Bookmark, isBookmarked: boolean) => {
		if (isRegistered()) {
			if (isBookmarked) {
				removeBookmark(newsItem).then(() => {
					loadBookmarks()
				})
			} else {
				addBookmark(newsItem).then(() => {
					loadBookmarks()
				})
			}
			return true
		} else {
			setShowSignInModal(true)
			return false
		}
	}

	const handleNextPageButton = () => {
		setPagesLoading(true)
		inputProps.clickLoadMoreNews()
	}

	const handleToTopButton = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	const loadBookmarks = () => {}

	return (
		<div className='flex flex-col'>
			<div className={`flex flex-row w-screen mb-10 h-auto overflow-y-scroll`}>
				<div className='lg:w-1/4 sm:w-0' />
				<div className='flex flex-col lg:w-1/2 sm:w-full sm:px-5 gap-5'>
					{newsList &&
						usersBookmarkSet &&
						newsList.map((n: Rns) => {
							return (
								<NewsCard
									key={n.id}
									showCompanyDetail={false}
									showPrice={false}
									news={n}
									onClick={handleNewsSelected}
									isBookmarked={usersBookmarkSet.has(n.id)}
									onBookmarkClick={bookmarkClick}
								/>
							)
						})}
					{inputProps.newsPaging && (
						<div className='flex w-full'>
							<div className='flex flex-col w-1/2 h-14 border-r justify-center text-center bg-slate-700'>
								<button className='flex items-center justify-center h-max p-5 max-h-10 text-center' onClick={handleNextPageButton}>
									{pagesLoading ? 'LOADING..' : 'LOAD MORE'}
								</button>
							</div>
							<div className='flex flex-col w-1/2 h-14 border-l justify-center text-center bg-slate-700'>
								<button className='flex items-center justify-center h-max p-5 max-h-10 text-center' onClick={handleToTopButton}>
									BACK TO TOP
								</button>
							</div>
						</div>
					)}
					<div className=''>
						<NewsModel
							id={newsSelected?.id}
							isOpen={newsSelected !== undefined}
							title={newsSelected?.generatedData ? newsSelected.generatedData.headline : newsSelected?.headline}
							originalTitle={newsSelected?.headline}
							url={newsSelected?.url}
							dateTime={newsSelected?.date ?? undefined}
							longSummary={newsSelected?.generatedData ? newsSelected?.generatedData.longSummary : newsSelected?.headline}
							bulletPoints={newsSelected?.generatedData ? newsSelected?.generatedData.bulletPoints : undefined}
							handleClose={handleNewsModelClose}
							newsId={newsSelected?.id}
							ticker={inputProps?.ticker}
							companyIndustry={newsSelected?.company?.industry}
							companyMarketCap={newsSelected?.company?.marketCap}
							keyDates={newsSelected?.generatedData?.keyDates}
							sentimentScore={newsSelected?.generatedData?.sentimentScore}
							sentimentReasoning={newsSelected?.generatedData?.sentimentReasoning}
							companyDetail={newsSelected?.generatedData?.companyDetail}
							financials={newsSelected?.generatedData?.financials}
						/>
					</div>
				</div>
				<div className='lg:w-1/4 sm:w-0'></div>
			</div>
			{showSignInModal && <SignInRegisterModal isOpen={showSignInModal} onClose={() => setShowSignInModal(false)} />}
		</div>
	)
}

export default NewsView
