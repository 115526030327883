import React from 'react'
import { Icon } from '../Icon/Icon'

export type ProgressSpinnerProps = {
	containerClassName?: string
	className?: string
	size?: 'small' | 'medium' | 'large'
	isPage?: boolean
	message?: string
}

export const ProgressSpinner: React.FC<ProgressSpinnerProps> = ({ containerClassName, className, size, message, isPage = false }) => (
	<div className='flex flex-col w-full h-full fixed inset-0 items-center justify-center gap-3'>
		<Icon type='iconAnimation' className='w-10 h-10' />
		<div className=' text-white font-bold'>{message || 'Loading...'}</div>
	</div>
)
