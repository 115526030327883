export enum VolumePressure {
	LOW = 'low',
	MEDIUM = 'medium',
	HIGH = 'high',
}

export async function getVolumePressure(currentDayVolume: number, tenDayAverage: number, threeMonthAverage: number): Promise<VolumePressure> {
	const calculateStandardDeviation = (data: number[], average: number): number => {
		const variance = data.reduce((sum, value) => sum + (value - average) ** 2, 0) / data.length
		return Math.sqrt(variance)
	}

	const tenDayData = [tenDayAverage, currentDayVolume]
	const standardDeviationTenDay = calculateStandardDeviation(tenDayData, (tenDayAverage + currentDayVolume) / 2)

	const threeMonthData = [threeMonthAverage, tenDayAverage, currentDayVolume]
	const standardDeviationThreeMonth = calculateStandardDeviation(threeMonthData, (threeMonthAverage + tenDayAverage + currentDayVolume) / 3)

	// Check current day volume pressure
	if (currentDayVolume === tenDayAverage && currentDayVolume === threeMonthAverage) {
		// If all values are the same (and therefore the standard deviation is 0), return undefined.
		return VolumePressure.MEDIUM
	} else if (currentDayVolume < tenDayAverage - standardDeviationTenDay && currentDayVolume < threeMonthAverage - standardDeviationThreeMonth) {
		return VolumePressure.LOW
	} else if (
		currentDayVolume >= tenDayAverage - standardDeviationTenDay &&
		currentDayVolume < tenDayAverage + standardDeviationTenDay &&
		currentDayVolume >= threeMonthAverage - standardDeviationThreeMonth &&
		currentDayVolume < threeMonthAverage + standardDeviationThreeMonth
	) {
		return VolumePressure.MEDIUM
	} else {
		return VolumePressure.HIGH
	}
}

export function mapMarketCap(mcap: string | undefined): string | undefined {
	if (!mcap) return undefined
	const mcapNumber = Number(mcap)
	if (mcapNumber < 237e6) {
		return 'Micro Cap'
	} else if (mcapNumber < 1.58e9) {
		return 'Small Cap'
	} else if (mcapNumber < 7.9e9) {
		return 'Mid Cap'
	} else {
		return 'Large Cap'
	}
}
