import React, { useEffect, useState } from 'react'
import { Icon } from '../../atoms/Icon/Icon'
import { shortenUrl } from '../../../services/url-short'
import { ProgressSpinner } from '../../atoms/ProgressSpinner'

export interface ShareMenuInput {
	url: string
	hashtags?: string
	text?: string
}

const ShareMenu = (inputProps: ShareMenuInput) => {
	const [copySuccess, setCopySuccess] = useState('')
	const [twitterSharing, setTwitterSharing] = useState(false)

	const shareToTwitter = () => {
		setTwitterSharing(true)
		shortenUrl(inputProps.url).then((shortUrl) => {
			let twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(`${inputProps.text}\n\n${shortUrl}\n\n${inputProps.hashtags?.toLowerCase()}`)}`
			window.open(twitterUrl, '_blank')
			setTwitterSharing(false)
		})
	}

	const copyToClipboard = () => {
		navigator.clipboard
			.writeText(`${inputProps.text}\n\n${inputProps.url}\n\n${inputProps.hashtags}`)
			.then(() => setCopySuccess('Copied!'))
			.catch((err) => console.error('Failed to copy: ', err))
	}

	const shareToLinkedIn = () => {
		const linkedInUrl = `https://www.linkedin.com/feed/?shareActive&mini=true&text=${encodeURIComponent(
			`${inputProps.text}\n\n${inputProps.url}\n\n${inputProps.hashtags?.toLowerCase()}`,
		)}`
		window.open(linkedInUrl, '_blank')
	}

	return (
		<div className='p-2 rounded shadow bg-msMedium border-msDark w-[200px] border border-white/10'>
			<ul className='space-y-4'>
				<li>
					<button onClick={shareToTwitter} className='hover:bg-mslight flex items-center w-full'>
						<Icon className='h-4 mr-2' type={'social_x'} />
						<div>Share to X</div>
						{twitterSharing && <ProgressSpinner />}
					</button>
				</li>
				<li>
					<button onClick={shareToLinkedIn} className='hover:bg-mslight flex items-center w-full'>
						<Icon className='h-4 mr-2' type={'social_linkedin'} />
						<div>Share to LinkedIn</div>
					</button>
				</li>
				<li>
					<button onClick={copyToClipboard} className='hover:bg-mslight flex items-center w-full'>
						<Icon className='h-4 mr-2' type={'clipboard'} />
						<div>Copy to Clipboard</div>
					</button>
					{copySuccess && <span className='text-sm text-gray-400 ml-2'>{copySuccess}</span>}
				</li>
			</ul>
		</div>
	)
}

export default ShareMenu
