import { BrokerTargets } from '../../../types/company'

export interface BrokerTargetViewProps {
	targets?: Array<BrokerTargets> | undefined
}

const BrokerTargetView = ({ targets }: BrokerTargetViewProps) => {
	if (targets == undefined) {
		return <div className='flex justify-center items-start w-full mt-6'>Loading..</div>
	}

	if ((targets?.length && !targets[0].newRecommendation) || targets.length === 0) {
		return <div className='flex justify-center items-start w-full mt-6'>No Broker Targets Available</div>
	}
	console.log(targets)

	return (
		<div className='flex justify-center items-start w-full mt-6'>
			<div className='w-full max-w-6xl overflow-x-auto'>
				<table className='table-auto w-full bg-msDark text-white border-gray-200'>
					<thead>
						<tr className='w-full bg-msDark text-white uppercase text-xs md:text-sm leading-normal'>
							<th className='py-2 px-4 text-left'>Date</th>
							<th className='py-2 px-4 text-left'>Broker Name</th>
							<th className='py-2 px-4 text-left'>New Recommendation</th>
							<th className='py-2 px-4 text-left'>Price</th>
							<th className='py-2 px-4 text-left'>Old Price Target</th>
							<th className='py-2 px-4 text-left'>New Price Target</th>
							<th className='py-2 px-4 text-left'>Broker Change</th>
						</tr>
					</thead>
					<tbody className='text-xs md:text-sm font-light text-white'>
						{targets.map((target, index) => (
							<tr key={index} className='border-b border-gray-200'>
								<td className='py-2 px-4 text-left whitespace-nowrap'>{target.date}</td>
								<td className='py-2 px-4 text-left'>{target.brokerName}</td>
								<td className='py-2 px-4 text-left uppercase'>{target.newRecommendation}</td>
								<td className='py-2 px-4 text-left'>{target.price}</td>
								<td className='py-2 px-4 text-left'>{target.oldPriceTarget}</td>
								<td className='py-2 px-4 text-left'>{target.newPriceTarget}</td>
								<td className='py-2 px-4 text-left uppercase'>{target.brokerChange}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default BrokerTargetView
