import React, { useState, FormEvent } from 'react'
import Modal from '../../atoms/Modal/Modal'
import { sendFeedback } from '../../../services/rns'

interface FeedbackFormProps {
	isOpen: boolean
	onClose: () => void
	rnsId?: string
	ticker?: string
	subject: string | undefined
}

const FeedbackForm: React.FC<FeedbackFormProps> = ({ isOpen, onClose, rnsId, ticker, subject }) => {
	const [message, setMessage] = useState<string>('')
	const [submitting, setSubmitting] = useState<boolean>(false)
	const [error, setError] = useState<string | null>(null)

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault()
		setSubmitting(true)
		if (message.length > 0 && rnsId && ticker) {
			sendFeedback(rnsId, ticker, message).then(() => {
				setMessage('')
				onClose()
				setSubmitting(false)
			})
		}
	}

	if (!isOpen) return null

	return (
		<Modal isOpen={isOpen} contentClassName='h-1/2' modelContentClassName='items-center'>
			<div className='p-6 rounded-lg max-w-md mx-auto bg-mslight shadow-xl'>
				<h2 className='text-2xl font-semibold mb-4'>Provide Feedback</h2>
				<div className='py-1 italic pb-2'>{subject}</div>
				<form onSubmit={handleSubmit} className='space-y-4'>
					<div>
						<label htmlFor='message' className='block text-sm font-medium text-white'>
							Message
						</label>
						<textarea
							id='message'
							value={message}
							onChange={(e) => setMessage(e.target.value)}
							className='mt-1 block w-full px-3 py-2 text-black bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
						/>
					</div>
					{error && <p className='text-red-500 text-sm'>{error}</p>}
					<div className='flex justify-end gap-2'>
						<button
							type='button'
							onClick={onClose}
							className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
						>
							Close
						</button>
						<button
							type='submit'
							disabled={submitting}
							className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
						>
							Submit Feedback
						</button>
					</div>
				</form>
			</div>
		</Modal>
	)
}

export default FeedbackForm
