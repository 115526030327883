import React from 'react'
import NewsTimelineItem from '../../components/molecules/NewsTimelineItem/NewsTimelineItem'

// Define an interface for the news item
interface NewsItem {
	id: string
	title: string
	content: string
	timestamp: string // Assuming format "YYYY-MM-DD"
}

const ComingUpView = () => {
	const newsData = [
		{ id: '1', title: 'Title 1', content: 'Content 1', timestamp: '2021-01-01' },
		{ id: '2', title: 'Title 2', content: 'Content 2', timestamp: '2021-03-01' },
		{ id: '3', title: 'Title 3', content: 'Content 3', timestamp: '2021-03-01' },
		{ id: '4', title: 'Title 4', content: 'Content 4', timestamp: '2021-04-01' },
		{ id: '5', title: 'Title 5', content: 'Content 5', timestamp: '2021-05-01' },
	]

	const getMonthName = (monthNumber: string) => {
		const date = new Date(0, parseInt(monthNumber) - 1)
		return date.toLocaleString('default', { month: 'long' })
	}

	// Group news data by month
	const groupByMonth = (items: NewsItem[]) => {
		return items.reduce((groups: { [key: string]: NewsItem[] }, item: NewsItem) => {
			const month = item.timestamp.split('-')[1]
			if (!groups[month]) {
				groups[month] = []
			}
			groups[month].push(item)
			return groups
		}, {})
	}

	const newsByMonth = groupByMonth(newsData)

	return (
		<div className='flex h-screen overflow-auto font-sans text-white bg-msDark'>
			<div className='w-1/4 p-4 border-r border-gray-700'>
				{Object.keys(newsByMonth).map((month) => (
					<div key={month} className='text-lg font-semibold mb-4'>
						{getMonthName(month)}
					</div>
				))}
			</div>
			<div className='w-3/4 p-4'>
				{Object.entries(newsByMonth).map(([month, items]) => (
					<div key={month} className='mb-6'>
						{items.map((item: NewsItem) => (
							<NewsTimelineItem key={item.id} newsData={item} />
						))}
					</div>
				))}
			</div>
		</div>
	)
}

export default ComingUpView
