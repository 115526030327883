import { Icon } from '../../components/atoms/Icon/Icon'

const Discord = () => {
	const handleHome = () => {
		window.location.href = '/'
	}

	return (
		<div className='flex flex-col gap-6 bg-msDark pt-[120px] h-screen text-white px-4'>
			<div className='text-center'>
				<h2 className='text-3xl font-bold'>Join our free Discord chat community!</h2>
				<p className='mt-4 text-lg'>Stay updated and engage with other members by joining our Discord chat group.</p>
				<div className='flex justify-center gap-4 mt-6'>
					<a
						href='https://discord.com/invite/HPjw34vvYJ'
						target='_blank'
						rel='noopener noreferrer'
						className='inline-flex items-center px-6 py-3 bg-blue-700 text-white rounded hover:bg-blue-500 w-44 text-center'
					>
						<Icon type='social_discord' className='w-7 h-7 mr-2 text-slate-300' />
						Join Discord
					</a>
					<a
						rel='noopener noreferrer'
						onClick={handleHome}
						className='inline-block px-6 py-3 border border-gray-500 text-white rounded hover:bg-msMedium w-44 text-center hover:cursor-pointer'
					>
						No Thanks!
					</a>
				</div>
				<div className='flex justify-center items-center rounded-xl mt-8 '>
					<img src='/discord.png' alt='Discord' className='lg:w-1/4 w-3/4 rounded-xl shadow-2xl' />
				</div>
			</div>
		</div>
	)
}

export default Discord
