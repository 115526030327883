import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/autoplay'

import { Market } from '../../molecules/Market/Market'
import { MarketPrice } from '../../../types/company'

interface MarketPanelProps {
	marketPrices?: MarketPrice[]
}

export const MarketPanel = (inputProps: MarketPanelProps | undefined) => {
	const marketPanels = ['^FTSE', '^FTAI', '^FTMC', '^GSPC'].map((symbol) => (
		<SwiperSlide key={symbol}>
			<Market marketPrice={inputProps?.marketPrices?.find((mp) => mp.symbol === symbol)} />
		</SwiperSlide>
	))

	return (
		<div className='w-full z-0'>
			{/* Desktop View */}
			<div className='hidden xl:flex w-full h-full flex-row gap-3'>
				{marketPanels.map((panel, index) => (
					<div key={index} className='w-1/4 h-full'>
						{panel.props.children}
					</div>
				))}
			</div>

			{/* Mobile View */}
			<div className='xl:hidden p-4'>
				<Swiper
					modules={[Pagination, Autoplay]}
					spaceBetween={10}
					slidesPerView={2}
					direction='horizontal'
					slidesPerGroup={2}
					autoplay={{
						delay: 5000,
						disableOnInteraction: true,
					}}
					pagination={{
						clickable: true,
						type: 'bullets',
						el: '.swiper-pagination', // Specify the pagination element
						bulletClass: 'swiper-pagination-bullet', // Specify the bullet class
						bulletActiveClass: 'swiper-pagination-bullet-active', // Specify the active bullet class
					}}
				>
					{marketPanels}
				</Swiper>
			</div>
		</div>
	)
}
