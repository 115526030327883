import React, { useState } from 'react'
import { Icon } from '../../atoms/Icon/Icon'
import FeedbackForm from '../FeedbackForm/FeedbackForm'

export interface MoreMenuInput {
	subject?: string
	rnsId?: string
	ticker?: string
}

const MoreMenu = (input: MoreMenuInput) => {
	const [isFeedbackFormOpen, setIsFeedbackFormOpen] = useState(false)

	const feedback = () => {
		setIsFeedbackFormOpen(true)
		console.log(isFeedbackFormOpen)
	}

	return (
		<div className='p-2 rounded shadow bg-msMedium border border-msDark w-[200px]'>
			<ul className='space-y-4'>
				<li>
					<button onClick={feedback} className='hover:bg-mslight flex items-center w-full'>
						<Icon className='h-4 mr-2' type={'flag'} />
						<div>Send Feedback</div>
					</button>
				</li>
			</ul>

			{/* Feedback Form Modal */}
			<FeedbackForm isOpen={isFeedbackFormOpen} onClose={() => setIsFeedbackFormOpen(false)} ticker={input.ticker} rnsId={input.rnsId} subject={input.subject} />
		</div>
	)
}

export default MoreMenu
