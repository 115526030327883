import React, { useState } from 'react'

interface TooltipProps {
	text: string | undefined
	children: any
}

const Tooltip: React.FC<TooltipProps> = ({ text, children }) => {
	const [showTooltip, setShowTooltip] = useState(false)

	const handleMouseEnter = () => {
		setShowTooltip(true)
	}

	const handleMouseLeave = () => {
		setShowTooltip(false)
	}

	return (
		<div className='relative inline-block'>
			<div className='inline-block cursor-pointer' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
				{children}
			</div>
			{showTooltip && text && <div className='flex bg-gray-500 text-white absolute z-10 rounded-md text-sm p-2 w-auto'>{text}</div>}
		</div>
	)
}

export default Tooltip
