import React, { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { LoggedInAuthResponse, signOut } from '../../../services/auth'
import ProfileIcon from '../../atoms/ProfileIcon/ProfileIcon'

interface UserMenuProps {
	authResult?: LoggedInAuthResponse | undefined
}

const UserMenu = (props: UserMenuProps) => {
	const navigate = useNavigate()
	const [isOpen, setIsOpen] = useState(false)
	const menuRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
				setIsOpen(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => document.removeEventListener('mousedown', handleClickOutside)
	}, [])

	const handleContactUs = () => {
		const email = 'info@marketshareai.uk'
		window.open(`mailto:${email}`, '_blank')
	}

	const handleSignOut = () => {
		signOut().then(() => {
			window.location.href = '/'
		})
	}

	return (
		<div className='relative' ref={menuRef}>
			<button onClick={() => setIsOpen(!isOpen)} className='w-[29px] h-[29px] transition-all duration-75 ease-in-out hover:scale-105 hover:shadow-lg'>
				<ProfileIcon firstName={props.authResult!.user!.firstName} />
			</button>
			{isOpen && (
				<div className='absolute right-0 mt-2 py-2 w-48 bg-mslight text-white rounded-md shadow-xl z-20 '>
					{/* <hr className='border-t border-gray-600 mx-2' /> Horizontal line styled with Tailwind CSS */}
					<a href='#' onClick={handleContactUs} className='block px-4 py-2 text-sm hover:bg-msbright'>
						Contact Us
					</a>
					<hr className='border-t border-gray-600 mx-2' /> {/* Horizontal line styled with Tailwind CSS */}
					<a href='#' onClick={handleSignOut} className='block px-4 py-2 text-sm hover:bg-msbright'>
						Sign Out
					</a>
				</div>
			)}
		</div>
	)
}

export default UserMenu
