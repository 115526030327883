import { ReactNode } from 'react'

interface ModalProps {
	isOpen?: boolean
	onClose?: () => void
	children: ReactNode
	contentClassName?: string // New prop for custom content class
	modelContentClassName?: string // Old prop for custom content class
	onClick?: any
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, contentClassName, modelContentClassName, onClick }) => {
	const modalWrapperClasses = isOpen ? `fixed inset-0 flex justify-center overflow-y-auto ${modelContentClassName}` : 'hidden'
	const overlayClasses = isOpen ? 'fixed inset-0 backdrop-blur-sm bg-black bg-opacity-40' : 'hidden'

	return (
		<div onClick={onClick} className='z-50'>
			<div className={overlayClasses}></div>
			<div className={modalWrapperClasses} onClick={onClose}>
				<div className={contentClassName} onClick={(e) => e.stopPropagation()}>
					{children}
				</div>
			</div>
		</div>
	)
}

export default Modal
