import React, { useEffect, useState } from 'react'
import Button from '../../components/atoms/Button/Button'
import { AuthResult, persistRegisteredUser, resentVerifyEmail, signIn, signUp, verifyEmail } from '../../services/auth'
import { useLocation } from 'react-router-dom'
import { Icon } from '../../components/atoms/Icon/Icon'

const SignUp = () => {
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)
	const forwardUrl: string = `${queryParams.get('forward_url')}`

	const [email, setEmail] = useState<string>('')
	const [password, setPassword] = useState<string>('')
	const [firstname, setFirstName] = useState<string>('')
	const [surname, setSurname] = useState<string>('')
	const [verificationCode, setVerificationCode] = useState<string>('')
	const [showPassword, setShowPassword] = useState<boolean>(false)
	const [selectedPlan, setSelectedPlan] = useState<'FREE' | 'PRO' | undefined>()
	const [authResult, setAuthResult] = useState<AuthResult>()
	const [verify, setVerify] = useState<boolean>(false)
	const [signupError, setSignupError] = useState<string>('')
	const [passwordPolicy, setPasswordPolicy] = useState({
		minLength: false,
		number: false,
		specialChar: false,
		uppercase: false,
		lowercase: false,
	})
	const [validations, setValidations] = useState({
		emailValid: false,
		firstnameValid: false,
		surnameValid: false,
	})
	const [isVerificationComplete, setIsVerificationComplete] = useState<boolean>(false) // New state

	const toggleShowPassword = () => {
		setShowPassword(!showPassword)
	}

	const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value
		setFirstName(value)
		setValidations({ ...validations, firstnameValid: value.length > 1 })
	}

	const handlesurnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value
		setSurname(value)
		setValidations({ ...validations, surnameValid: value.length > 1 })
	}

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value
		setEmail(value)
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		setValidations({ ...validations, emailValid: emailRegex.test(value) })
	}

	const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const val = event.target.value
		setPassword(val)
		setPasswordPolicy({
			minLength: val.length >= 8,
			number: /\d/.test(val),
			specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(val),
			uppercase: /[A-Z]/.test(val),
			lowercase: /[a-z]/.test(val),
		})
	}

	const handleVerificationCode = (event: React.ChangeEvent<HTMLInputElement>) => {
		setVerificationCode(event.target.value)
	}

	const signup = () => {
		setSignupError('')
		signUp(email, password, firstname, surname, selectedPlan!)
			.then(() => {
				setVerify(true)
			})
			.catch((err: { message: string }) => {
				const words = err.message.split(/(?=[A-Z])/)
				let errorString = words
					.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
					.join(' ')
					.replace('Exception', '')
					.trim()
				if (errorString == 'Username Exists') {
					errorString = 'Email Already In Use'
				}
				setSignupError(errorString)
			})
	}

	useEffect(() => {
		if (authResult) {
			if (authResult.AccessToken.length > 1) {
				persistRegisteredUser(authResult).then(() => {
					window.location.href = '/discord'
				})
			}
		}
	}, [authResult])

	const verifyEmailClick = () => {
		setSignupError('')
		verifyEmail(email, verificationCode)
			.then((res) => {
				setVerify(true)
				signIn(email, password).then((authRes) => {
					setAuthResult(authRes)
					setIsVerificationComplete(true) // Set verification as complete
				})
			})
			.catch((err) => {
				const words = err.message.split(/(?=[A-Z])/)
				const errorString = words
					.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
					.join(' ')
					.replace('Exception', '')
					.trim()
				setSignupError(errorString)
			})
	}

	const resendCodeClick = () => {
		resentVerifyEmail(email).then((resp) => {
			console.log('Verification code resent', resp)
		})
	}

	const inputClass = (condition: boolean) =>
		condition
			? 'w-full px-3 py-2 border border-green-300 rounded-sm bg-msDark focus:outline-none'
			: 'w-full px-3 py-2 border border-gray-500 rounded-sm bg-msDark hover:border-white focus:border-white focus:outline-none'

	const policyTextClass = (condition: boolean) => (condition ? 'text-green-500' : 'text-white')

	const selectPlan = (plan: 'FREE' | 'PRO' | undefined) => {
		setSelectedPlan(plan)
	}

	const renderPlanSelection = () => (
		<div className='flex justify-center items-center space-x-3'>
			<div className='flex flex-col justify-between w-1/2 p-4 border border-gray-300 rounded h-full'>
				<h2 className='text-lg font-bold mb-4'>Free Plan</h2>
				<ul className='list-disc pl-5 space-y-2'>
					<li>Trending News Feed</li>
					<li>Basic Filtering</li>
					<li>Discord community access</li>
					<li>5 Bookmarks</li>
				</ul>
				<button onClick={() => selectPlan('FREE')} className='mt-4 w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-700'>
					Select Free
				</button>
			</div>
			<div className='flex flex-col justify-between w-1/2 p-4 border border-gray-300 rounded h-full'>
				<h2 className='text-lg font-bold mb-4'>
					<span className='flex flex-row'>
						<div>Pro Plan </div>
						<div className='blur-sm'>- £X.XX / month</div>
					</span>
				</h2>
				<ul className='list-disc pl-5 space-y-2 blur-sm'>
					<li>This is a feature</li>
					<li>This is another one</li>
					<li>Oh yes, well done for looking</li>
					<li>Nothing to see here</li>
				</ul>
				<button disabled={true} onClick={() => selectPlan('PRO')} className='cursor-wait mt-4 w-full py-2 bg-green-500 text-white rounded hover:bg-green-700'>
					Select Pro (Coming Soon)
				</button>
			</div>
		</div>
	)

	const signup2NextDisabled =
		validations.emailValid === false ||
		validations.firstnameValid === false ||
		validations.surnameValid === false ||
		Object.values(passwordPolicy).every(Boolean) == false ||
		email.length == 0 ||
		firstname.length == 0 ||
		surname.length == 0

	return (
		<div className='space-y-4 bg-msDark text-white pt-[100px] w-full h-screen justify-center flex flex-row p-3 xl:p-40'>
			<div className='flex flex-col w-full lg:w-[900px] gap-2'>
				<h2 className='pb-8 font-extrabold text-4xl'>Sign Up.</h2>
				{!selectedPlan ? (
					renderPlanSelection()
				) : !verify ? (
					<div className='flex flex-col gap-2'>
						<div>
							<label htmlFor='firstname' className='block font-semibold'>
								first name.
							</label>
							<div className='mt-1'>
								<input id='firstname' onChange={handleFirstNameChange} name='firstname' type='text' required className={inputClass(validations.firstnameValid)} />
							</div>
						</div>
						<div>
							<label htmlFor='surname' className='block font-semibold'>
								last name.
							</label>
							<div className='mt-1'>
								<input id='surname' onChange={handlesurnameChange} name='surname' type='text' required className={inputClass(validations.surnameValid)} />
							</div>
						</div>
						<div>
							<label htmlFor='email' className='block font-semibold'>
								email address.
							</label>
							<div className='mt-1'>
								<input id='email' onChange={handleEmailChange} name='email' type='email' autoComplete='email' required className={inputClass(validations.emailValid)} />
							</div>
						</div>
						<div>
							<label htmlFor='password' className='block font-semibold'>
								password.
							</label>
							<div className='mt-1'>
								<input
									id='password'
									onChange={handlePasswordChange}
									name='password'
									type={showPassword ? 'text' : 'password'}
									autoComplete='current-password'
									required
									className={inputClass(Object.values(passwordPolicy).every(Boolean))}
								/>
								<div className='mt-2'>
									<label htmlFor='showPassword' className='inline-flex items-center'>
										<input id='showPassword' type='checkbox' checked={showPassword} onChange={toggleShowPassword} className='rounded shadow-sm' />
										<span className='ml-2'>Display password</span>
									</label>
								</div>
								<ul className='mt-2'>
									<li className={policyTextClass(passwordPolicy.minLength)}>Length min 8 characters</li>
									<li className={policyTextClass(passwordPolicy.number)}>Contains at least 1 number</li>
									<li className={policyTextClass(passwordPolicy.specialChar)}>Contains at least 1 special character</li>
									<li className={policyTextClass(passwordPolicy.uppercase)}>Contains at least 1 uppercase letter</li>
									<li className={policyTextClass(passwordPolicy.lowercase)}>Contains at least 1 lowercase letter</li>
								</ul>
							</div>
						</div>
						<div className='text-red-500 font-semibold'>{signupError}</div>
						<div className='flex w-full border'>
							<Button
								className={`w-full align-middle p-5 font-bold ${!signup2NextDisabled && 'bg-green-400 text-black'}`}
								onClick={signup}
								text='next.'
								disabled={signup2NextDisabled}
							/>
						</div>
					</div>
				) : !isVerificationComplete ? (
					<div className='flex flex-col gap-2'>
						<div>
							<div className='flex pb-4 font-bold'>
								<p>Check your email for a verification code</p>
							</div>
							<label htmlFor='surname' className='block font-semibold'>
								Email Verification Code
							</label>
							<div className='mt-1'>
								<div className='mt-1'>
									<input id='verification' onChange={handleVerificationCode} name='verification' type='text' required className={inputClass(validations.emailValid)} />
								</div>
							</div>
						</div>
						<div className='text-red-500 font-semibold'>{signupError}</div>
						<div className='flex flex-row gap-2'>
							<Button onClick={resendCodeClick} text='re-send code.' className='w-full align-middle p-5 bg-msMedium hover:bg-mslight text-white rounded' />
							<Button
								disabled={verificationCode.length != 6}
								onClick={verifyEmailClick}
								text='verify.'
								className='w-full align-middle p-5 disabled:border enabled:bg-green-500 enabled:hover:bg-green-700 text-white rounded'
							/>
						</div>
					</div>
				) : (
					<></>
				)}
			</div>
		</div>
	)
}

export default SignUp
