import { ApolloClient, ApolloLink, FetchResult, HttpLink, InMemoryCache, Observable, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { LoggedInAuthResponse, getGuestToken, refreshAuth, validateUser } from './auth'
import { onError } from '@apollo/client/link/error'

const serviceMap: Map<string, string> = new Map<string, string>([
	['company', 'uncnd33punavfmp7rgnlwwbfyq'],
	['rns', 'f42fnxtdhbfrto7fmhtj7bjfvi'],
	['chat', 'bipzldrwzvdmxm7lp6ago6djgm'],
	['url-short', 'bomaljxmlrgdhoiejj6mhlhm44'],
	['user', 'wcveuigm2ncyxdjkkswurl64ei'],
])

const errorLink = onError(({ graphQLErrors, forward, operation }) => {
	console.log('errorLink', JSON.stringify(graphQLErrors))
	if (graphQLErrors) {
		for (let err of graphQLErrors) {
			console.log('appolo err', JSON.stringify(err))
			if ((err as any).errorType === 'UnauthorizedException' || (err as any).errorType === 'NotAuthorizedException') {
				return new Observable((observer) => {
					console.log('refreshing auth')
					refreshAuth()
						.then(() => {
							const oldHeaders = operation.getContext().headers
							const newToken = JSON.parse(localStorage.getItem('sessionAuth')!).access_token
							operation.setContext({
								headers: {
									...oldHeaders,
									authorization: `Bearer ${newToken}`,
								},
							})
							const subscriber = {
								next: observer.next.bind(observer),
								error: observer.error.bind(observer),
								complete: observer.complete.bind(observer),
							}
							forward(operation).subscribe(subscriber)
						})
						.catch((error) => observer.error(error))
				})
			}
		}
	}
	return forward(operation)
})

const authLink = setContext(async (_, { headers }) => {
	let sessionAuth = localStorage.getItem('sessionAuth')
	if (!sessionAuth) {
		await getGuestToken()
		sessionAuth = localStorage.getItem('sessionAuth')
		if (!sessionAuth) {
			throw new Error('Unable to get sessionAuth')
		}
	}

	const session = JSON.parse(sessionAuth) as LoggedInAuthResponse
	validateUser(session)

	return {
		headers: {
			...headers,
			authorization: session ? `Bearer ${session.access_token}` : '',
		},
	}
})

export async function getServiceClient(name: string) {
	const httpLink = new HttpLink({
		uri: `https://${serviceMap.get(name)}.appsync-api.eu-west-2.amazonaws.com/graphql`,
	})

	const link = ApolloLink.from([authLink, errorLink, httpLink])

	return new ApolloClient({
		link,
		cache: new InMemoryCache(),
	})
}
