import React, { useCallback, useRef } from 'react'
import { Switch } from '@headlessui/react'
import { NewsFacets } from '../../../types/rnsList'

export interface Filters {
	sentimentOptions: Record<'very_bad' | 'bad' | 'neutral' | 'good' | 'very_good', boolean>
	marketCap: Record<'large' | 'mid' | 'small' | 'micro', boolean>
	todayOnly: boolean
}

interface NewsFilterProps {
	filters: Filters
	className?: string
	onFilterChange: (filters: Filters) => void
	newsFacets?: NewsFacets
}

function useDebouncedCallback(callback: (...args: any[]) => void, delay: number) {
	const timerRef = useRef<NodeJS.Timeout | null>(null)

	const debouncedCallback = useCallback(
		(...args: any[]) => {
			if (timerRef.current) {
				clearTimeout(timerRef.current)
			}
			timerRef.current = setTimeout(() => {
				callback(...args)
			}, delay)
		},
		[callback, delay],
	)

	return debouncedCallback
}

const NewsFilter: React.FC<NewsFilterProps> = ({ filters, className, newsFacets, onFilterChange }) => {
	const debouncedFilterChange = useDebouncedCallback(onFilterChange, 300)

	const handleSentimentChange = (sentiment: keyof Filters['sentimentOptions']) => {
		const checkedCount = Object.values(filters.sentimentOptions).filter(Boolean).length
		if (filters.sentimentOptions[sentiment] && checkedCount === 1) {
			return
		}
		const updatedSentiment = {
			...filters.sentimentOptions,
			[sentiment]: !filters.sentimentOptions[sentiment],
		}
		const updatedFilters = { ...filters, sentimentOptions: updatedSentiment }
		debouncedFilterChange(updatedFilters)
	}

	const handleMarketCapChange = (cap: keyof Filters['marketCap']) => {
		const checkedCount = Object.values(filters.marketCap).filter(Boolean).length
		if (filters.marketCap[cap] && checkedCount === 1) {
			return
		}
		const updatedMarketCap = {
			...filters.marketCap,
			[cap]: !filters.marketCap[cap],
		}
		const updatedFilters = { ...filters, marketCap: updatedMarketCap }
		debouncedFilterChange(updatedFilters)
	}

	const handleTodayOnlyChange = () => {
		const updatedFilters = { ...filters, todayOnly: !filters.todayOnly }
		debouncedFilterChange(updatedFilters)
	}

	const sentimentColors = {
		very_bad: 'bg-red-600 bg-opacity-30 border-red-400 border',
		bad: 'bg-orange-500 bg-opacity-30 border-orange-400 border',
		neutral: 'bg-yellow-500 bg-opacity-30 border-yellow-400 border',
		good: 'bg-green-500 bg-opacity-30 border-green-400 border',
		very_good: 'bg-blue-500 bg-opacity-30 border-blue-400 border',
	}

	const sentimentUncheckedColors = 'bg-gray-500'

	return (
		<div className={`pt-4 gap-3 flex flex-col w-full p-5 rounded-xl bg-gray-800 shadow-lg ${className}`}>
			{renderTodayOnlyOption()}
			<hr className='border-t border-gray-600 my-2' />
			<div className='w-full'>
				{renderSentimentOptions()}
				<hr className='border-t border-gray-600 my-2' />
				<div className='mb-4'>
					<h3 className='font-normal text-xl mb-1 text-white'>Market Cap</h3>
					{renderMarketCapOptions(newsFacets)}
				</div>
			</div>
		</div>
	)

	function renderSentimentOptions() {
		const sentiments: (keyof Filters['sentimentOptions'])[] = ['very_bad', 'bad', 'neutral', 'good', 'very_good']
		return (
			<div className='flex flex-col mb-4 w-full'>
				<h3 className='font-normal text-xl mb-1 text-white'>Sentiment</h3>
				<div className='flex flex-col gap-2 justify-start'>
					{sentiments.map((sentiment) => {
						const checked = filters.sentimentOptions[sentiment]
						const gradientClass = checked ? sentimentColors[sentiment] : sentimentUncheckedColors

						return (
							<div key={sentiment} onClick={() => handleSentimentChange(sentiment)} className='flex capitalize items-center gap-2 cursor-pointer'>
								<Switch
									checked={checked}
									onChange={() => handleSentimentChange(sentiment)}
									className={`rounded-full group inline-flex h-6 w-11 items-center border data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50 border-gray-400`}
								>
									<span
										className={`inline-block h-4 w-4 transform rounded-full transition ${checked ? `translate-x-6 ${gradientClass}` : `translate-x-1 ${gradientClass}`}`}
									/>
								</Switch>
								<div className='font-light text-white'>{sentiment.replace('_', ' ')}</div>
							</div>
						)
					})}
				</div>
			</div>
		)
	}

	function renderMarketCapOptions(newsFacets: NewsFacets | undefined) {
		const marketCaps: (keyof Filters['marketCap'])[] = Object.keys(filters.marketCap) as (keyof Filters['marketCap'])[]

		return (
			<div className='flex flex-col mb-4 w-full'>
				<div className='flex flex-col gap-2 justify-start'>
					{marketCaps.map((cap) => {
						const checked = filters.marketCap[cap]
						const gradientClass = checked ? 'bg-white' : 'bg-gray-400'

						return (
							<div key={cap} onClick={() => handleMarketCapChange(cap)} className='flex capitalize items-center gap-2 cursor-pointer'>
								<Switch
									checked={checked}
									onChange={() => handleMarketCapChange(cap)}
									className={`rounded-full group inline-flex h-6 w-11 items-center border data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50 border-gray-400`}
								>
									<span
										className={`inline-block h-4 w-4 transform rounded-full transition ${checked ? `translate-x-6 ${gradientClass}` : `translate-x-1 ${gradientClass}`}`}
									/>
								</Switch>
								<div className='font-light text-white'>{cap.replace('_', ' ')}</div>
								<div className='text-base font-light tracking-wide text-slate-300'>{newsFacets ? `(${getMarketcapCount(cap, newsFacets)})` : ''}</div>
							</div>
						)
					})}
				</div>
			</div>
		)
	}

	function renderTodayOnlyOption() {
		const checked = filters.todayOnly
		const gradientClass = checked ? 'bg-white' : 'bg-gray-400'

		return (
			<div onClick={handleTodayOnlyChange} className='flex capitalize items-center gap-2 cursor-pointer my-1'>
				<Switch
					checked={checked}
					onChange={handleTodayOnlyChange}
					className={`rounded-full group inline-flex h-6 w-11 items-center border data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50 border-gray-400`}
				>
					<span className={`inline-block h-4 w-4 transform rounded-full transition ${checked ? `translate-x-6 ${gradientClass}` : `translate-x-1 ${gradientClass}`}`} />
				</Switch>
				<div className='font-light text-white whitespace-nowrap'>Today Only</div>
			</div>
		)
	}

	function getMarketcapCount(cap: string, newsFacets: NewsFacets): number | string {
		if (!newsFacets) return ''
		if (cap === 'large') return newsFacets.facetCounts.LargeCap
		if (cap === 'mid') return newsFacets.facetCounts.MidCap
		if (cap === 'small') return newsFacets.facetCounts.SmallCap
		if (cap === 'micro') return newsFacets.facetCounts.MicroCap
		return ''
	}
}

export default NewsFilter
