import React from 'react'

interface ProfileIconProps {
	firstName: string
}

const ProfileIcon: React.FC<ProfileIconProps> = ({ firstName }) => {
	const firstLetter = firstName ? firstName[0].toUpperCase() : ''

	return <div className='w-full h-full bg-msDark border rounded-full flex items-center justify-center text-white text-sm font-bold'>{firstLetter}</div>
}

export default ProfileIcon
