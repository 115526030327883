import React, { useState, useEffect } from 'react'

interface PulsingLightProps {
	color: string
	size: string
}

const PulsingLight: React.FC<PulsingLightProps> = ({ color, size }) => {
	const [visible, setVisible] = useState(true)

	useEffect(() => {
		const interval = setInterval(() => {
			setVisible((prevVisible) => !prevVisible)
		}, 1000) // Change the pulsing speed by adjusting the interval duration

		return () => {
			clearInterval(interval)
		}
	}, [])

	const lightStyle: React.CSSProperties = {
		backgroundColor: color,
		width: size,
		height: size,
		opacity: visible ? 1 : 0,
		transition: 'opacity 0.6s ease-in-out',
		borderRadius: '50%', // Make the element round
	}

	return (
		<div className='flex justify-center items-center border-2 rounded-xl border-slate-500'>
			<div className='animate-pulse p-0.5'>
				<div style={lightStyle}></div>
			</div>
		</div>
	)
}

export default PulsingLight
