import { useEffect, useState } from 'react'
import InfoChip from '../../atoms/InfoChip/InfoChip'
import { faArrowTrendUp as bull } from '@fortawesome/free-solid-svg-icons'
import { faArrowTrendDown as bear } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Company } from '../../../types/company'
import { VolumePressure, getVolumePressure } from '../../../utils/company'
import { faThermometerFull as pressureHigh } from '@fortawesome/free-solid-svg-icons'
import { faThermometerHalf as pressureMed } from '@fortawesome/free-solid-svg-icons'
import { faThermometerEmpty as pressureLow } from '@fortawesome/free-solid-svg-icons'

export interface CompanyStatsProps {
	company: Company
}

const CompanyStats = (props: CompanyStatsProps) => {
	const [bullBearIcon, setBullBearIcon] = useState<any>()
	const [bullBearText, setBullBearText] = useState<string>()

	const [volumePressureText, setVolumePressureText] = useState<string>()
	const [volumePressureIcon, setVolumePressureIcon] = useState<any>()

	useEffect(() => {
		if (props.company.fiftyDayAverage > props.company.twoHundredDayAverage) {
			setBullBearIcon(<FontAwesomeIcon icon={bull} size='1x' className='h-5 text-white' />)
			setBullBearText('Bullish')
		} else {
			setBullBearIcon(<FontAwesomeIcon icon={bear} size='1x' className='h-5 text-white' />)
			setBullBearText('Bearish')
		}
		getVolumePressure(props.company.regularMarketVolume, props.company.averageDailyVolume10Day, props.company.averageDailyVolume3Month).then((pressure: string) => {
			switch (pressure) {
				case VolumePressure.HIGH:
					setVolumePressureText('High')
					setVolumePressureIcon(<FontAwesomeIcon icon={pressureHigh} size='1x' className='h-5 text-white' />)
					break
				case VolumePressure.MEDIUM:
					setVolumePressureText('Medium')
					setVolumePressureIcon(<FontAwesomeIcon icon={pressureMed} size='1x' className='h-5 text-white' />)
					break
				case VolumePressure.LOW:
					setVolumePressureText('Low')
					setVolumePressureIcon(<FontAwesomeIcon icon={pressureLow} size='1x' className='h-5 text-white' />)
					break
				default:
					break
			}
		})
	}, [props.company])

	return (
		<div className='flex lg:flex-row flex-col w-full'>
			<div className='flex flex-row w-full lg:gap-2 gap-6 justify-between'>
				<div className='w-1/2'>
					<InfoChip title='Sector' text={props.company.sector} />
				</div>
				<div className='w-1/2'>
					<InfoChip title='Market Cap' text={props.company.marketCap} />
				</div>
			</div>
			<div className='flex flex-row w-full lg:gap-0 gap-6 justify-between'>
				<div className='w-full'>
					<InfoChip
						title='Price Trend'
						icon={bullBearIcon}
						text={bullBearText!}
						toolTip={`50 Day Avg. (${Math.round(props.company.fiftyDayAverage)}) is ${bullBearText === 'Bullish' ? 'Above' : 'Below'} the 200 Day Avg. (${Math.round(
							props.company.twoHundredDayAverage,
						)})`}
					/>
				</div>
				<div className='w-full'>
					<InfoChip
						title='Volume Pressure'
						icon={volumePressureIcon}
						text={volumePressureText!}
						toolTip={`Todays Volume (${props.company.regularMarketVolume.toString()}) is ${
							volumePressureText === 'High' ? 'Above' : volumePressureText === 'Low' ? 'Below' : ''
						} Average`}
					/>
				</div>
			</div>
		</div>
		// <div className='flex flex-col gap-5 sm:flex-row'>
		// 	<div className='flex flex-wrap gap-5 md:flex-nowrap md:items-start md:justify-starto'>
		// 		<InfoChip title='Sector' text={props.company.sector} />
		// 		<InfoChip title='Market Cap' text={props.company.marketCap} />
		// 	</div>
		// 	<div className='flex gap-5 flex-nowrap items-center w-auto'>
		// 		<InfoChip
		// 			title='Price Trend'
		// 			icon={bullBearIcon}
		// 			text={bullBearText!}
		// 			toolTip={`50 Day Avg. (${Math.round(props.company.fiftyDayAverage)}) is ${bullBearText === 'Bullish' ? 'Above' : 'Below'} the 200 Day Avg. (${Math.round(
		// 				props.company.twoHundredDayAverage,
		// 			)})`}
		// 		/>
		// 		<InfoChip
		// 			title='Volume Pressure'
		// 			icon={volumePressureIcon}
		// 			text={volumePressureText!}
		// 			toolTip={`Todays Volume (${props.company.regularMarketVolume.toString()}) is ${
		// 				volumePressureText === 'High' ? 'Above' : volumePressureText === 'Low' ? 'Below' : ''
		// 			} Average`}
		// 		/>
		// 	</div>
		// </div>
	)
}

export default CompanyStats
