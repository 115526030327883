export interface ButtonProps {
	text: any
	onClick: any
	disabled?: boolean
	className?: string
}

const Button = (props: ButtonProps) => {
	return (
		<button className={`flex items-center justify-center h-full  border-gray-300 max-h-10 text-center ${props.className}`} onClick={props.onClick} disabled={props.disabled}>
			{props.text}
		</button>
	)
}

export default Button
