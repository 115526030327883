import React from 'react'

interface NewsItem {
	id: string
	title: string
	content: string
	timestamp: string // Format: "YYYY-MM-DD" or "YYYY-MM to YYYY-MM"
}

interface NewsTimelineItemProps {
	newsData: NewsItem
}

const NewsTimelineItem: React.FC<NewsTimelineItemProps> = ({ newsData }) => {
	const formatDate = (timestamp: string): string => {
		// Handle different formats of timestamps here
		return timestamp // Format as needed
	}

	return (
		<div className='flex flex-col space-y-4'>
			<div key={newsData.id} className='border-l-4 border-blue-500 pl-4'>
				<h3 className='text-lg font-semibold'>{newsData.title}</h3>
				<p className='text-sm text-gray-600'>{formatDate(newsData.timestamp)}</p>
				<p className='text-md text-gray-800'>{newsData.content}</p>
			</div>
		</div>
	)
}

export default NewsTimelineItem
